import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollAnim from './ScrollAnim';

import { appearAlert, disappearAlert } from './utils';

export default class PG_Account {
    constructor(app) {
        this.app = app;
        gsap.registerPlugin(ScrollTrigger);

        this.view = document.querySelector('.account');
        this.header = this.view.querySelector('.layer-header');
        this.container = this.view.querySelector('.item-container')


        this.can_delete = false;

        this.creditInfo = {};

        this.scrollAnim = new ScrollAnim(this.app, true)
        const url = window.location.search;
        const params = new URLSearchParams(url);
        const id = params.get("id");
        if (id) {
            this.id = id;
        } else {
            window.location.href = '/'
        }

        this.init()
    }
    init() {
        if (this.id) {
            this.getDATA()

            if (this.id == this.app.id) {
                this.isMyAccount = true;
                document.querySelector('.button-account').classList.add('is-selected')
            }
        }
    }
    async getDATA() {
        try {
            const respose = await this.app.getAccount(this.id)

            this.can_delete = respose.can_delete;
            // console.log(33, respose.generate_images)

            if (this.can_delete) {
                document.querySelector('.util-container').classList.remove('hidden')

                document.querySelector('.button-logout').addEventListener('click', () => {
                    document.querySelector('.button-logout').blur()
                    this.app.logout()
                })
                gsap.utils.toArray('.button-account').forEach(b => {
                    b.disabled = true;
                    b.classList.add('is-selected')
                })

                const creditResponse = await this.app.getCredit();

                if (creditResponse) {
                    this.creditInfo = creditResponse;
                }
                
            }

            const { generate_images } = respose;

            if (generate_images) {
                if (generate_images.length > 0) {

                    generate_images.map((r, i) => {
                        this.createItem(r);
                        if (i == generate_images.length - 1) {
                            this.scrollAnim.scrollTransition()
                            
                        }
                    })
                } else {

                    const content = document.createElement('div');
                    content.classList.add('empty-text');
                    content.innerHTML = `
                        <div class="text-inner">
                            <div class="title">아직 이미지가 없어요</div>
                            <div class="description">
                                하단 버튼을 통해 이미지를 만들고<br />마이페이지를 채워주세요
                            </div>
                        </div>
                        <button class="button-link button-glow">
                            <span class="background"></span>
                            <span class="label">시작하기</span>
                            <span class="glow-effect">
                                <span class="effect-1"></span>
                            </span>
                        </button>
                    `;
                    this.container.appendChild(content);
                    content.querySelector('.button-link').addEventListener('click', () => {
                        window.location.href = `/generate`
                    })

                    document.querySelector('.button-grid').disabled = true;
                }
            }

            if (respose) {
                this.intro()
            }

            this.createHeader(respose);
            
        } catch(error) {
        }
    }

    intro() {
        gsap.to(this.view, {
            autoAlpha: 1,
            duration: 0.2, 
            ease: 'power1.inOut',
            delay: 0.2
        })
    }

    initClick(id) {
        const targetUrl = `/image?id=${id}`;
        window.location.href = targetUrl;
        // try {
        //     const respose = await this.app.getDetailData(id);
        //     if (respose) {
        //         console.log('response : ', respose);
        //     }
        // } catch(error) {
        //     console.log('error')
        // }
    }

    createHeader(data) {
        const { name, team_name, generate_images } = data;
        this.header.innerHTML = `
            <div class="row-container row-name">
                <div class="name">${name}</div>
                ${name !== '' ? `<div class="team">${team_name}</div>` : ``}
            </div>
            <div class="row-container">
                <div class="row-container row-image-length">
                    <span class="icon-photo"></span>
                    <span class="count">${generate_images.length}</span>
                </div>
                ${this.can_delete && this.creditInfo.max_credits ? 
                    `<div class="row-container row-credit">
                        <span class="icon-credit"></span>
                        <span class="count">${this.creditInfo.used_credits}</span>
                        <span class="icon-slash"></span>
                        <span class="count">${this.creditInfo.max_credits}</span>
                    </div>
                    `
                    :``
                }
            </div>
        `

    }


    createItem(data) {

        const { id, generative_image, generative_image_thumbnail, total_reaction_count, recent_reactions, created_by } = data;

        let created = {
            name: 'username',
            team_name: 'team_name'
        }

        if (data.created_by) {

            const { name, team_name } = data.created_by;

            created.name = name;
            created.team_name = team_name;
            
        }

        const content = document.createElement('div');
        content.classList.add('item-image')

        content.style.setProperty('--ox', this.opacityValue);

        content.innerHTML = `
            <div class="thumb">
                <img src=${generative_image_thumbnail} alt="${id}">
            </div>
            <div class="information-deck">
                <div class="tag tag-reaction">
                    <div class="tag-leading tag-reactions"></div>
                    <div class="tag-trailing tag-count"></div>
                </div>
            </div>
            <a class="hidden-link" href="/image?id=${id}"></a>
        `;


        if (this.can_delete) {
            
            const button = document.createElement('button');

            button.innerHTML =`<span class="icon-delete">삭제하기</span>`;
            button.classList.add('button-icon')
            button.classList.add('button-delete')
            content.querySelector('.information-deck').appendChild(button)

            button.addEventListener('click', (e) => {
                e.preventDefault()
                const _alert = appearAlert();
                // console.log({alert})

                gsap.utils.toArray('.button-alert', _alert).forEach(button => {
                    if (button.classList.contains('button-confirm')) {
                        button.setAttribute('data-gtm-id', 'myDelComplete')
                    }
                    button.addEventListener('click', () => {
                        if (button.classList.contains('button-confirm')) {
                            const _callback = () => {
                                disappearAlert(_alert)
                                location.reload()
                            }
                            this.app.postDeleteImage(id, content, _callback) 
                        } else {
                            disappearAlert(_alert)
                        }
                    })
                })
            })
        }

        if (recent_reactions.length > 0) {
            content.querySelector('.tag-reactions').innerHTML = 
                `${recent_reactions.map((reaction, i) => `
                    <span class="symbol symbol-${reaction.name}"></span>`
                )
                .join('')}`


        
            content.querySelector('.tag-count').innerHTML = `<span class="tag-text">${total_reaction_count}</span>`
        } else {
      
            content.querySelector('.tag-reactions').innerHTML = `<span class="symbol"><img src="/assets/icon/icon-reaction-empty.png" /></span>`
        }

        content.querySelector('img').onload = function(){
            gsap.to(content.querySelector('img'), { opacity: 1, duration: 0.8, ease: 'power1.inOut' })
            gsap.to(content.querySelector('.information-deck'), { opacity: 1, duration: 0.8, ease: 'power1.inOut' })
        };
        

        this.scrollAnim.initElementState()
        this.container.appendChild(content);

        
    }
    deleteImage(content) {
        gsap.to(content, { autoAlpha: 0, duration: 0.6, ease: 'power1.inOut', onComplete: () => {
            gsap.set(content, { display: 'none' })
            this.scrollAnim.updateElements(0)
        }})
    }
}