import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { lerp } from './utils';
import ButtonGlow from './ButtonGlow';
import ScrollAnim from './ScrollAnim';

import TextTranslate from './TextTranslate';

export default class PG_Privacy {
    constructor(app) {
        this.app = app;
        gsap.registerPlugin(ScrollTrigger);
        this.init()
    }


    init() {
        gsap.to('.layer-content', { 
            autoAlpha: 1,
            duration: 0.4, 
            ease: 'power1.inOut'
        })
    }
}

