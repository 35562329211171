import './scss/common.css';
import App from './App';
import gsap from 'gsap';
import PG_Index from './PG_Index';
import PG_Generate from './PG_Generate';
import PG_Generate2 from './PG_Generate2';
import PG_Image from './PG_Image';
import PG_Account from './PG_Account';
import PG_Login from './PG_Login';
import PG_Privacy from './PG_Privacy';
import PG_Signup from './PG_Signup';

// 페이지 매핑 객체
const pageMap = {
  'generate': PG_Generate,
  'account': PG_Account,
  'index': PG_Index,
  'login': PG_Login,
  'privacy': PG_Privacy,
  'image': PG_Image,
  'signup': PG_Signup
};

async function loadPage() {
  const app = new App();
  await app.init(); // App 초기화 완료 대기
  app.setupInterceptors();


  gsap.utils.toArray('.button-text').forEach(b => {
    b.addEventListener('click', function() {
      this.blur(); // 포커스 해제
    })
  })
  // 페이지 클래스 초기화
  const pageSelector = Object.keys(pageMap).find(selector => document.body.dataset.screen === selector);

  if (pageSelector) {
    const PageClass = pageMap[pageSelector];
    new PageClass(app);
  }

  // 로그인 상태에 따른 UI 조정 (App.init에서 이미 확인됨)
  const isLoggedIn = app.isLoggedInStatus; // 캐싱된 값 사용
  const generateButton = document.querySelector('.button-move-generate');
  if (generateButton) {
    document.body.classList.toggle('not-loggedIn', !isLoggedIn);

    if (isLoggedIn) {
      generateButton.querySelector('.label').innerHTML = '시작하기';
      generateButton.setAttribute('data-gtm-id', 'listLoginStart');
      gsap.utils.toArray('.button-account').forEach(button => {
        button.setAttribute('data-gtm-id', 'listLoginAccount')
      })
    } else {

      localStorage.removeItem('signupCompleted')
      generateButton.querySelector('.label').innerHTML = '로그인하고 시작하기';
      generateButton.setAttribute('data-gtm-id', 'listLogoutStart');
      gsap.utils.toArray('.button-account').forEach(button => {
        button.setAttribute('data-gtm-id', 'listLogoutMy')
      })
    }


    
  }

  gsap.to('.app', { opacity: 1, duration: 0.2, ease: 'power1.inOut' });
  window.app = app;
}

window.onload = loadPage;