import p5 from 'p5';
import gsap from 'gsap';

export default function sketch(s) {


    const screenWidth = 310;
    const screenHeight = 310;
    const cx = screenWidth / 2;
    const cy = screenHeight / 2;
    
    const wNum = 3;
    const hNum = 3;
    
    const buttons = [];
    const buttonWidth = 90;
    const buttonHeight = 90;
    const buttonMargin = 10;
    const boxRadius = 10;

    let previousIndex = -1;

    const sx = cx - (wNum * (buttonWidth + buttonMargin) - buttonMargin) / 2;
    const sy = cy - (hNum * (buttonHeight + buttonMargin) - buttonMargin) / 2;


    let mixId;
    let mixCount = 0;
    let mixCountMax = 100;

    let isStarted = false;
    let canvas;
    let img;
    
    let minutes = 0;
    let seconds = 0;
    let tens = 0;
    let interval;
    let isPaused = false;
    let pausedTime = 0;

    class GameUI {
        constructor() {

            this.layer = document.querySelector('.layer-game')
            this.buttonStart = this.layer.querySelector('.button-game-start');
            this.buttonReStart = this.layer.querySelector('.button-game-retry');

            this.buttonHint = this.layer.querySelector('.button-game-hint');

            this.layerStart = this.layer.querySelector('.ui-inner.start');
            this.layerGame = this.layer.querySelector('#canvas-destination')
            this.layerFinish = this.layer.querySelector('.ui-inner.finish')

            this.layerBackground = this.layer.querySelector('.background')

            this.opacityValue = 0.3;

            this.init()

            // this.appear(this.layerStart)
        }

        init() {
            this.buttonStart.addEventListener('click', () => {
                this.buttonStart.disabled = true;
                isStarted = false;
                this.transition_GameStart();
            })
            this.buttonReStart.addEventListener('click', () => {
                isStarted = false;
                this.transition_GameRestart();
            })

            this.buttonHint.addEventListener('click', () => {
                if (document.body.classList.contains('visible-hint')) {
                    document.body.classList.remove('visible-hint')
                } else {
                    document.body.classList.add('visible-hint')
                }
            })
        }

        start(restart) {
            if (isStarted) return;
            isStarted = true;
            
            if (restart) {
                reloadImage();
                initializeButtons();
            } else {
            }
            startMix();
            document.querySelector('.game-hint').querySelector('img').src = `/assets/game/game-${previousIndex}.jpg`;

        }
        // 시작 레이어 사라짐
        transition_GameStart() {
            if (isStarted) return;

            const duration = 0.2;
            const opacity = 0;

            window.GenerateUI.progress = 'ing';

            // console.log('timer length ', gsap.utils.toArray('.timer .num').length)
            tens = 0;
            seconds = 0;
            minutes = 0; // 분 초기화
            if (gsap.utils.toArray('.timer .num').length >= 3) {
                if (gsap.utils.toArray('.timer .num')[0]) {
                    gsap.utils.toArray('.timer .num')[0].remove()
                }
                if (gsap.utils.toArray('.timer .icon-dotgroup')[0]) {

                    gsap.utils.toArray('.timer .icon-dotgroup')[0].remove()
                }
            }

            gsap.set(this.layerStart, {
                pointerEvents: 'none',
                display: 'flex'
            })

            gsap.to(this.layerStart, {
                opacity: opacity,
                duration: duration,
                ease: 'power1.out',
                onComplete: () => {
                    gsap.set(this.layerStart, {
                        display: 'none',
                        opacity: 0,
                    });
                    this.start()
                }
            })
            gsap.to(this.layerBackground, {
                opacity: opacity,
                duration: duration,
                ease: 'power1.inOut',
            })
            gsap.to('.timer', {
                opacity: 1,
                duration: 0.4,
                ease: 'power1.inOut'
            })
        }

        // 종료 레이어 - 재시작
        transition_GameRestart() {
            console.log('transition_GameRestart')
            this.layer.classList.remove('is-prevent')
            
            if (document.body.classList.contains('visible-hint')) {
                document.body.remove('visible-hint')
            }
            window.GenerateUI.progress = 'ing';

            const duration = 0.2;
            const opacity = 0;
            // console.log('transition_GameRestart')
            gsap.set(this.layerFinish, {
                pointerEvents: 'none',
                display: 'flex'
            })
            gsap.to(this.layerBackground, {
                opacity: opacity,
                duration: duration,
                ease: 'power1.inOut',
            })
            gsap.to(this.layerFinish, {
                opacity: opacity,
                duration: duration,
                ease: 'power1.inOut',
                onComplete: () => {
                    gsap.set(this.layerFinish, {
                        display: 'none'
                    });
                    this.start(true)
                }
            })
        }
        
        transition_SuccessGame() {
            this.layer.classList.add('is-prevent')
            window.GenerateUI.progress = 'finish';
            clearInterval(interval)

            if (document.body.classList.contains('visible-hint')) {

                document.body.classList.remove('visible-hint')
            }

            
            gsap.to(this.layerBackground, {
                opacity: 1,
                duration: 0.3,
                ease: 'power1.inOut'
            })
            gsap.set(this.layerFinish, {
                display: 'flex',
                pointerEvents: 'auto'
            })
            gsap.to(this.layerFinish, {
                opacity: 1,
                duration: 0.3,
                ease: 'power1.out',
                onComplete: () => {
                }
            })
            // gsap.to('.timer .background', {
            //     opacity: 0,
            //     duration: 0.3,
            //     ease: 'power1.out',
            // })
        }

        goSuccess() {

            isStarted = false;
            this.transition_SuccessGame()
            clearInterval(interval)
        }

    }
    let gameUI = new GameUI()



    class Button {
        constructor(id) {
            this.enable = false;
            this.id = id;
            this.pos = id;
            this.label = id + 1;
            this.x = sx + this.pos % wNum * (buttonWidth + buttonMargin);
            this.y = sy + Math.floor(this.pos / wNum) * (buttonHeight + buttonMargin);
            this.graphics = null; // p5.Graphics 객체
        }
    
        createImage() {
            if (this.id == wNum * hNum - 1) return; // 빈 블록은 이미지 없음
    
            // 기존 그래픽 객체 제거
            if (this.graphics) {
                this.graphics = null;
            }
    
            // 블록 영역
            const imgX = (this.id % wNum) * (img.width / wNum);
            const imgY = Math.floor(this.id / wNum) * (img.height / hNum);
            const imgW = img.width / wNum;
            const imgH = img.height / hNum;
    
            // 그래픽 생성
            const tempGraphics = s.createGraphics(buttonWidth, buttonHeight);
            tempGraphics.image(img, 0, 0, buttonWidth, buttonHeight, imgX, imgY, imgW, imgH);
    
            // 마스크 생성
            const maskGraphics = s.createGraphics(buttonWidth, buttonHeight);
            maskGraphics.noStroke();
            maskGraphics.fill(255);
            maskGraphics.rect(0, 0, buttonWidth, buttonHeight, boxRadius); // 라운드 마스크
    
            // 마스크를 적용하여 최종 이미지 생성
            this.graphics = tempGraphics.get();
            this.graphics.mask(maskGraphics.get());
    
            // 불필요한 그래픽 객체 제거
            tempGraphics.remove();
            maskGraphics.remove();
        }
    
        updatePos(val) {
            const tx = sx + this.pos % wNum * (buttonWidth + buttonMargin);
            const ty = sy + Math.floor(this.pos / wNum) * (buttonHeight + buttonMargin);
    
            if (this.id == 9 || val == 0) {
                this.x = tx;
                this.y = ty;
            } else {
                gsap.to(this, {
                    x: tx,
                    y: ty,
                    duration: 0.35,
                    ease: 'power3.out'
                });
            }
        }
    
        display() {
            if (this.id == wNum * hNum - 1) return;
            if (this.graphics) {
                s.image(this.graphics, this.x, this.y);
            } else {
                s.noFill();
                s.stroke('#2E6FF1');
                s.strokeWeight(1);
                s.rect(this.x, this.y, buttonWidth, buttonHeight, boxRadius); // 테두리만 있는 박스
            }
        }
        isClicked(){
            if(!this.enable) return;
            if(s.mouseX > this.x && s.mouseX < this.x + buttonWidth && s.mouseY > this.y && s.mouseY < this.y + buttonHeight){
                if(this.id == wNum * hNum -1) return;
                this.checkMove();
            }
        }
    
        checkMove(){
            var posX = this.pos % wNum;
            var posY = Math.floor(this.pos / hNum);
            if(posX - 1 >= 0 && buttons[this.pos - 1].id == wNum * hNum - 1){
                clickedPos = this.pos;
                clickedDir = -1;
            }
            if(posX + 1 < wNum && buttons[this.pos + 1].id == wNum * hNum - 1){
                clickedPos = this.pos;
                clickedDir = 1;
            }
            if(posY - 1 >= 0 && buttons[this.pos - wNum].id == wNum * hNum - 1){
                clickedPos = this.pos;
                clickedDir = -wNum;
            }
            if(posY + 1 < hNum && buttons[this.pos + wNum].id == wNum * hNum - 1){
                clickedPos = this.pos;
                clickedDir = wNum;
            }
        }
    }
    
    s.clearGame = () => {
        
        tens = 0;
        seconds = 0;
        minutes = 0; // 분 초기화
        if (gsap.utils.toArray('.timer .num').length >= 3) {
            if (gsap.utils.toArray('.timer .num')[0]) {
                gsap.utils.toArray('.timer .num')[0].remove()
            }
            if (gsap.utils.toArray('.timer .icon-dotgroup')[0]) {

                gsap.utils.toArray('.timer .icon-dotgroup')[0].remove()
            }
        }
        const _text = gsap.utils.toArray('.timer .num');
        _text[0].innerHTML = seconds.toString().padStart(2, '0');
        _text[1].innerHTML = tens.toString().padStart(2, '0');
    }

    s.reinit = () => {
        reloadImage()
    }

    s.preload = () => {
        reloadImage()
    }
    
    s.setup = () => {
        const canvas = s.createCanvas(screenWidth, screenHeight);
        canvas.parent("#canvas-destination");
        
        s.angleMode(s.DEGREES);
        s.background('#20242A');

        for(var i = 0; i < wNum * hNum; i++){
            buttons.push(new Button(i));

        }
    
        // 이미지를 블록마다 분리
        for (let button of buttons) {
            button.createImage();
        }
        
    }



    s.draw = () => {
        if (isPaused) return; // 정지 상태일 때 아무 작업도 하지 않음
    
        s.background('#20242A');
        for (let button of buttons) {
            button.display();
        }

        if (!isStarted && interval) {
            clearInterval(interval)
        }

        const timerContainer = document.querySelector('.timer');
        const _text = gsap.utils.toArray('.timer .num');
    
        if (minutes > 0 && _text.length < 3) {

            const dotGroupElement = document.createElement('span');
            dotGroupElement.classList.add('icon-dotgroup');
            timerContainer.prepend(dotGroupElement);

            const minuteElement = document.createElement('span');
            minuteElement.classList.add('num');
            minuteElement.innerHTML = '000';
            timerContainer.prepend(minuteElement);
    
       
        }
    
        if (interval) {
            if (_text.length >= 3) {
                _text[0].innerHTML = minutes.toString().padStart(2, '0');
                _text[1].innerHTML = seconds.toString().padStart(2, '0');
                _text[2].innerHTML = tens.toString().padStart(2, '0');
            } else {
                _text[0].innerHTML = seconds.toString().padStart(2, '0');
                _text[1].innerHTML = tens.toString().padStart(2, '0');
            }
        }
  
    };
    
    function reinit() {
        s.reinit()
    }
    function startMix() {
        if (!interval) {
            clearInterval(interval)
        }
        if (document.querySelector('.is-prevent')) {
            document.querySelector('.is-prevent').classList.remove('is-prevent')
        }
        mixCount = 0;
        mixId = setInterval(mix, 10);
        s.playLoop()
        tens = 0;
        seconds = 0;
        minutes = 0; // 분 초기화
    }
    
    function startTimer() {
        tens++;
        if (tens > 99) {
            seconds++;
            tens = 0;
        }
        if (seconds > 59) {
            minutes++;
            seconds = 0;
        }
    }

    function mix() {
        mixCount += 1;
        if (mixCount > mixCountMax) {
            clearInterval(mixId);

            for (var button of buttons) {
                button.enable = true;
            }

            tens = 0;
            seconds = 0;
            interval = setInterval(startTimer, 10);
        }
        // var mixPos = [];
        var mixDirArray = [];
        var blank;
        for (var button of buttons) {
            if (button.id == wNum * hNum - 1) {
                blank = button;
            }
        }
        var posX = blank.pos % wNum;
        var posY = Math.floor(blank.pos / hNum);
        if (posX - 1 >= 0) {
            mixDirArray.push(-1);
        }
        if (posX + 1 < wNum) {
            mixDirArray.push(1)
        }
        if (posY - 1 >= 0) {
            mixDirArray.push(-3)
        }
        if (posY + 1 < hNum) {
            mixDirArray.push(3)
        }

        if (mixDirArray.length <= 0) return;
        var rnd = Math.floor(Math.random() * mixDirArray.length)
        var blankPos = blank.pos;
        var mixDir = mixDirArray[rnd];

        if (mixDir) {
            var temp = buttons[blankPos];
            var nowPos = buttons[blankPos].pos
            var nexPos = buttons[blankPos + mixDir].pos
            buttons[blankPos] = buttons[blankPos + mixDir];
            buttons[blankPos + mixDir] = temp;
            buttons[nowPos].pos = nowPos;
            buttons[nexPos].pos = nexPos;
            buttons[nowPos].updatePos(0);
            buttons[nexPos].updatePos(0);
        }
    }



    var clickedPos = 0;
    var clickedDir = 0;

    s.touchStarted = (e) => {


        if (!isStarted) {
            // console.log("Game not started yet.");
            return;
        }
        // 캔버스 내 클릭 처리
        if (isClickInsideCanvas(e)) {
            // console.log("Canvas clicked at:", s.mouseX, s.mouseY);
            clickedPos = 0;
            clickedDir = 0;
            for (let button of buttons) {
                button.isClicked();
            }
            if (clickedDir != 0) {
                var temp = buttons[clickedPos];
                var nowPos = buttons[clickedPos].pos
                var nexPos = buttons[clickedPos + clickedDir].pos
                buttons[clickedPos] = buttons[clickedPos + clickedDir];
                buttons[clickedPos + clickedDir] = temp;
                buttons[nowPos].pos = nowPos;
                buttons[nexPos].pos = nexPos;
                buttons[nowPos].updatePos(1);
                buttons[nexPos].updatePos(1);
            }
    
            checkSuccess();
            return false;
        }


    }

    s.playLoop = () => {
        if (!isPaused) return; // 이미 실행 중이면 아무 작업도 하지 않음
        isPaused = false;
        s.loop(); // p5.js 루프 재개
        interval = setInterval(startTimer, 10); // 타이머 재개
        // console.log("Game Resumed");
    };
    
    s.stopLoop = () => {
        if (isPaused) return; // 이미 정지 상태라면 아무 작업도 하지 않음
        isPaused = true;
        s.noLoop(); // p5.js 루프 정지
        clearInterval(interval); // 타이머 정지
        // console.log("Game Paused");
    };
    

    function isClickInsideCanvas(e) {
        const canvasElement = document.querySelector("#canvas-destination canvas");
        return canvasElement && canvasElement.contains(e.target);
    }

    function checkSuccess() {
        var count = 0;
        for (var button of buttons) {
            if (button.id == button.pos) {
                count += 1;
            }
        }
        if (count == wNum * hNum) {
            for (var button of buttons) {
                button.enable = false;
            }

            clearInterval(interval);

            gsap.delayedCall(0.01, () => {
                
                gameUI.goSuccess()
            })
        }
    }

    function reloadImage() {

        previousIndex++;
        if (previousIndex >= 4) {
            previousIndex = 0;
        }

        // 이미지 로드
        s.loadImage(`/assets/game/game-${previousIndex}.jpg`, (loadedImage) => {
            img = loadedImage; // 로드 완료 후 img 업데이트
            initializeButtons(); // 이미지 로드 후 버튼 초기화
        }, (err) => {
            console.error("Image failed to load:", err);
        });
    }
    
    
    
    function restartGame() {
        isStarted = false;
    
        reloadImage();
        initializeButtons();
        startMix();
    }
    function initializeButtons() {
        buttons.forEach(button => {
            button.createImage();
            button.updatePos(0); // 위치 초기화
        });

 

    }
    

}