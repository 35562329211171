import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { lerp } from './utils';
import ButtonGlow from './ButtonGlow';
import ScrollAnim from './ScrollAnim';
import TextTranslate from './TextTranslate';

export default class PG_Login {
    constructor(app) {
        this.app = app;
        this.form = document.querySelector('#form-login');
        this.username = this.form.querySelector('#username');

        this.password = this.form.querySelector('#password')
        this.buttonSubmit = this.form.querySelector('.button-login');
        this.buttonPassword = this.form.querySelector('.button-show-password');

        if (this.app.isLoggedInStatus) {
            window.location.replace('/'); // 메인 화면으로 리다이렉트
        }

        this.validateError = false;

        this.nickname_MAXLENGTH = 20;

        this.checkLoginStatus()
        this.init();
    }

    init() {
        this.initLogin = this.initLogin.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.initEvents();
        this.init_kakao()

        this.progress_login('appear')

    }

    async checkLoginStatus() {
        const isLoggedIn = await this.app.isLoggedIn();
        if (isLoggedIn) {
            window.location.href = '/'; // 로그인된 경우 index로 리다이렉트
        }
    }

    initEvents() {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                if (this.validateForm()) {
                    this.initLogin();
                }
                event.preventDefault(); 
            }
        });
    
        // 포커스 아웃 시 이메일 형식 체크
        this.username.addEventListener('blur', this.blurInput.bind(this));
        // 실시간 유효성 검사 (버튼 상태 업데이트)
        this.username.addEventListener('input', this.validateForm.bind(this));
        this.password.addEventListener('input', this.validateForm.bind(this));
    
        this.buttonPassword.addEventListener('click', () => {
            if (this.password.type === 'password') {
                this.password.type = 'text';
                this.form.classList.add('is-text');
            } else {
                this.password.type = 'password';
                this.form.classList.remove('is-text');
            }
        });
    
        // 버튼 클릭 이벤트 처리
        this.buttonSubmit.addEventListener('click', () => {
            if (this.validateForm()) {
                this.initLogin();
            }
        });
    
        gsap.utils.toArray('.button-layer').forEach(button => {
            button.addEventListener('click', () => {
                this.appearLayer(document.querySelector(`.layer-privacy[data-terms="${button.dataset.terms}"]`));
            });
        });
    
        gsap.utils.toArray('.layer-privacy').forEach(layer => {
            layer.querySelector('.button-close-layer').addEventListener('click', () => {
                this.disappearLayer(layer);
            });
        });
    }
    
    // 포커스 아웃 시 이메일 형식 체크
    blurInput(event) {
        const username = this.username.value.trim();
        const emailFormatRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const form = document.querySelector('#form-login');
        const errorElement = form.querySelector('.error');

        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (!emailRegex.test(username)) {
            errorElement.innerHTML = '이메일 형식으로 입력해 주세요';
        }

        if (username && !emailFormatRegex.test(username)) {
            this.validateError = true;
            errorElement.innerHTML = '이메일 형식으로 입력해 주세요';
            form.classList.add('is-error');
        } else {
            if (this.validateError) {
                this.validateError = false;
                form.classList.remove('is-error');
                gsap.delayedCall(0.1, () => {
                    errorElement.innerHTML = '';
                })
            }
        }
    }


    async initLogin() {
        try {
            const response = await this.app.login(this.username.value, this.password.value);

            if (this.buttonSubmit) {
                
                this.buttonSubmit.disabled = true;
            }

            if (response) {
                // console.log(response)
            }
        } catch (error) {
            if (this.buttonSubmit) {
                this.buttonSubmit.disabled = false;
            }
            // alert('e', response)
        }
    }

    disappearLayer(layer) {
        const sheet = layer.querySelector('.layer-content');
        const yv = 60;

     
        gsap.to(sheet, {
            opacity: 0,
            duration: 0.3,
            ease: "power1.inOut",
            onComplete: () => {
                // disableScroll();
           
            },
        });

        gsap.to(sheet, { y: (yv / 2), duration: 0.3, ease: "power1.inOut", onComplete: () => {
            sheet.scrollTo(0,0)
            gsap.set(layer, { display: 'none', pointerEvents: 'none' })
            gsap.killTweensOf(layer, 'all')
        } });

    }

    appearLayer(layer) {
        const sheet = layer.querySelector('.layer-content');
        const yv = 60;
        gsap.set(layer, { display: 'flex', zIndex: 1000, pointerEvents: 'auto'  })
        gsap.set(sheet, { y: yv })
        gsap.to(sheet, {
            opacity: 1,
            duration: 0.35,
            ease: "power2.out",
            delay: 0.1,
            onComplete: () => {
                // disableScroll();
            },
        });
        gsap.to(sheet, { y: 0, duration: 0.35, ease: "power2.out", delay: 0.1 });

    }

    async initLogin() {
        try {
            const response = await this.app.login(this.username.value, this.password.value);
            if (response) {
            }
        } catch (error) {
        }
    }

    apparTransition(layer, title, sub, _callback) {
        const content = layer.querySelectorAll('.layer-content');
        const floating = layer.querySelector('.layer-ui');

        // console.log({layer, title, sub, _callback})

        layer.classList.add('is-selected');

        gsap.set(layer, { display: 'flex', pointerEvents: 'auto', autoAlpha: 1 })
        gsap.to(content, { 
            autoAlpha: 1, 
            duration: 0.6,
            ease: 'power1.inOut',
            delay: 0.0,
            onComplete: () => {
           
            }
        })

        gsap.to(floating, { 
            autoAlpha: 1, 
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0.6,
            onComplete: () => {
                if (_callback) _callback()
            }
        })

        if (title) {
            title._class.appear(title.index)
        }

        if (sub) {
            sub._class.appear(sub.index, 0.3)
        }
    }
    disapparTransition(layer, _callback) {
        const content = layer.querySelectorAll('.layer-content');
        const floating = layer.querySelector('.layer-ui');

        layer.classList.remove('is-selected');

        gsap.to(layer, { 
            autoAlpha: 0, 
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0.0,
            onComplete: () => {
                gsap.set(layer, { display: 'none', pointerEvents: 'none' })
                if (_callback) _callback()
            }
        })
    }

    progress_login(state, callback = null) {
        const layer = document.querySelector('.layer-login');

        if (state == 'appear') {
            this.apperLayer(layer, callback)
        } else if (state === 'disappear') {
            this.disapperLayer(layer, callback)
        }
    }

    disapperLayer(layer, _callback) {
        gsap.set(layer, { pointerEvents: 'none' })
        gsap.to(layer, { 
            autoAlpha: 0, 
            duration: 0.3,
            ease: 'power1.inOut',
            delay: 0.0,
            onComplete: () => {
                gsap.set(layer, { display: 'none', onComplete: () => {
                    // gsap.set([layer], { clearProps: 'all' })
                    layer.classList.remove('is-selected');
                } })
                
                if (_callback) _callback()
            }
        })
    }

    apperLayer(layer, _callback) {
        if (layer.classList.contains('is-selected')) return;
        gsap.set(layer, { display: 'flex', pointerEvents: 'auto' })
        layer.classList.add('is-selected');

        gsap.to(layer, { 
            autoAlpha: 1, 
            duration: 0.25,
            ease: 'power1.inOut',
            onComplete: () => {
                if (_callback) _callback()
            }
        })
    }

    validateForm() {
        const username = this.username.value.trim(); // 이메일로 사용
        const password = this.password.value.trim();
        const loginButton = document.querySelector('.button-login'); // 로그인 버튼 선택
    
        const enableButton = () => {
            loginButton.disabled = false;
            loginButton.classList.remove('is-disabled');
        }
        
        const disableButton = () => {
            loginButton.disabled = true;
            loginButton.classList.add('is-disabled');
        }
    
        // 이메일 유효성 검사
        if (!username) {
            // console.log('이메일은 필수입니다');
            disableButton();
            return false;
        }
    
        // // 이메일 형식만 체크하는 정규표현식
        // const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        // if (!emailRegex.test(username)) {
        //     // console.log('유효한 이메일 형식이 아닙니다');
        //     disableButton();
        //     return false;
        // }
        
        // 패스워드 검사
        if (!password) {
            // console.log('패스워드는 필수입니다');
            disableButton();
            return false;
        }
        
        // 비밀번호 2자 이상 체크
        if (password.length < 2) {
            // console.log('패스워드는 2자 이상이어야 합니다');
            disableButton();
            return false;
        }
        
        enableButton();
        return true;
    }

    init_kakao() {
        this.Kakao = window.Kakao;
        console.log(this.Kakao)
        this.Kakao.init(process.env.KAKAO_CLIENT_ID)

        document.getElementById('kakao-login-button').addEventListener('click', () => {
            this.login_kakao();
        });
    }
    
    logout_kakao() {

        this.Kakao.API.request({
            url: '/v1/user/unlink',
        })
        .then(function(response) {
            console.log(response);
        })
        .catch(function(error) {
            console.log(error);
        });

            
        // this.Kakao.Auth.logout()
        // .then(function(response) {
        //     console.log(this.Kakao.Auth.getAccessToken()); // null
        // })
        // .catch(function(error) {
        //     console.log('Not logged in.');
        // });

    }

    async _oauth(id) {
        try {
            const response = await this.app.socialLogin(id);
            if (response) {
                const {
                    access,
                    refresh
                } = response.data;
    
                this.app.saveTokens(access, refresh);
    
                this.app.setupInterceptors();
    
                const redirectPath = localStorage.getItem('redirectAfterLogin') || '/';
                localStorage.removeItem('redirectAfterLogin'); // 저장된 리다이렉트 경로 제거
                window.location.href = redirectPath; // 기존 경로로 이동
   
                // 로그인 처리
            }
        } catch(error) {
            this.Kakao.Auth.authorize({
                redirectUri: 'https://staging.yepp.plus-ex.com/signup',
                // scope: 'account_email'
            });
        }
    }
    login_kakao() {
        this.Kakao.Auth.login({
            // redirectUri: 'http://localhost:3000/signup',
            success: (authObj) => {
                this.Kakao.API.request({
                    url: '/v2/user/me',
                    success: (res) => {
                        this._oauth(res.id)
                        // To.Grok : 여기서 id
                        // const { id } = res;

                        // const email = res.kakao_account.email;

                        // localStorage.setItem('prd_yepp-temp-id', id)
                        // localStorage.setItem('prd_yepp-temp-email', email)
                        // console.log({ id, email })
                        // if (id) {
                        //     // this.initSocialLogin(id, 0)
                        //     // this.app.userDATA = {
                        //     //     social_id: id,
                        //     //     social_type: 0,
                        //     //     email
                        //     // }
                        // }
                        // this.createInfo({
                        //     id,
                        //     name: nickname,
                        //     email: res.kakao_account.email,
                        //     platform: 'kakao '
                        // });
                    },
                    fail: (error) => {
                        console.error('사용자 정보 요청 실패:', error);
                    },
                });
            },
            fail: (err) => {
                console.error('로그인 실패:', err);
            },
        });
    }
    // login_kakao() {
    //     console.log('this.Kakao', this.Kakao.Auth.login)
    //     this.Kakao.Auth.authorize({
    //         redirectUri: 'http://localhost:3000/signup',
    //         success: (authObj) => {
    //             this.Kakao.API.request({
    //                 url: '/v2/user/me',
    //                 success: (res) => {
    //                     const { account_email } = res;

    //                     console.log({account_email})
                        
    //                     // const { nickname } = res.kakao_account.profile;

    //                     // if (id) {
    //                     //     this.app.socialLogin(id)
    //                     // }
    //                     // this.createInfo({
    //                     //     id,
    //                     //     name: nickname,
    //                     //     email: res.kakao_account.email,
    //                     //     platform: 'kakao '
    //                     // });
    //                 },
    //                 fail: (error) => {
    //                     console.error('사용자 정보 요청 실패:', error);
    //                 },
    //             });
    //         },
    //         fail: (err) => {
    //             console.error('로그인 실패:', err);
    //         },
    //     });
    // }
}
