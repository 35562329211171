import gsap from 'gsap';
import TextTranslate from './TextTranslate';
import {
    animateCounter
} from './utils';
export default class ButtonScaleAnim {

    // unselect :40

    constructor(selector, count, isLoggedIn) {
        this.target = selector;
        this.icon = this.target.querySelector('.symbol')
        this.backgroundPrev = this.target.querySelector('.background');
        this.background = this.target.querySelector('.background.--fill');
        this.shadow = this.target.querySelector('.shadow')

        this.inner = this.target.querySelector('.inner')

        this.textElement = this.target.querySelector('.count');

        this.isAnimating = false;

        this.scale = {
            select: 1.0,
            unselect: 0.8,
            press: 0.72
        }
    

        this.text = this.target.querySelector('.text-container');

        this.props_text = {
            select: this.target.getAttribute('data-text-color'),
            unselect: '#999999'
            // unselect: '#C5C6CD'
        }

        this.props_BG = {
            select: '#0F0F0F',
            unselect: '#dddddd'
            // unselect: '#5B5D63'
        }

        this.count = count;

        gsap.set(this.textElement, { color: this.props_text.unselect })
        gsap.set(this.icon, { backgroundColor: this.props_BG.unselect })
        gsap.set(this.backgroundPrev, { backgroundColor: '#999999' })


        this.TEXTANIM = new TextTranslate(this.text, 'lines'),
        this.isLoggedIn = isLoggedIn;
        this.init();

        this.addFloatingEffect()
    }

    init() {


        if (this.isLoggedIn) {
            this.target.addEventListener('pointerdown', this.press.bind(this));   
        }
        // this.target.addEventListener('touchstart', this.scaleDown.bind(this));
        // this.target.addEventListener('touchend', this.scaleUp.bind(this));
        // this.target.addEventListener('touchcancel', this.scaleUp.bind(this));

        this.initStyle()
    }
    initStyle() {

        if (this.target.classList.contains('selected')) {
            gsap.set(this.target, { scale: this.scale.select })
        } else {
            gsap.set(this.target, { scale: this.scale.unselect })
        }
    }

    press() {
        
        if (this.target.classList.contains('selected')) {
            // console.log('press : 미선택 -> 선택', gsap.getProperty(this.target, 'scale'))
            gsap.to(this.target, {
                scale: this.scale.select * 0.9, //0.9
                duration: 0.2,
                ease: 'power2.out',
                onComplete: () => {
                    this.target.classList.remove('is-animating');
                    // this.target.classList.remove('selected')
                }
            })


        } else {
            // unselect
            gsap.to(this.target, { 
                scale: this.scale.unselect * 0.9, // 0.72
                duration: 0.2,
                ease: 'power2.out',
                onComplete: () => {
                    this.target.classList.remove('is-animating');
                    // this.target.classList.add('selected')
                }
            })
        }

    }

    release(to) {
        // if (this.target.classList.contains('is-animating')) return;
        // this.target.classList.add('is-animating');

        this.target.disabled = true;
        // unselect -> select
        gsap.killTweensOf([ this.textElement, this.icon, this.background], 'all')
        if (to === 'select') {
            // console.log('release : 미선택 -> 선택', gsap.getProperty(this.target, 'scale'))
            
            // gsap.to(this.target, { scale: 0.9, duration: 0.2, ease: 'power2.out' })

            gsap.delayedCall(0, () => {
                gsap.to(this.target, { scale: 1.07, duration: 0.35, ease: 'power2.out' })
                gsap.to(this.target, { scale: 1.0, duration: 0.4, ease: 'power2.out', delay: 0.35, onComplete: () => {
                    this.target.classList.remove('is-animating');
                    this.target.classList.add('selected')
                    this.target.disabled = false;
                } })
                gsap.to(this.textElement, { color: this.props_text.select, duration: 0.4, ease: 'power1.inOut' })
                gsap.to(this.icon, { backgroundColor: this.props_BG.select, duration: 0.4, ease: 'power1.inOut' })
                gsap.to(this.background, { opacity: 1, duration: 0.4, ease: 'power1.inOut' })
            })
            this.countUp()

        } else {
            // select → unselect
            // console.log('release : 선택 -> 미선택', gsap.getProperty(this.target, 'scale'))
            // gsap.to(this.target, { scale: 1.0, duration: 0.4, ease: 'power2.out' })

            gsap.to(this.target, { scale: 0.97, duration: 0.3, ease: 'power1.out' })
            gsap.delayedCall(0.3, () => {
                gsap.to(this.target, { scale: 0.8, duration: 0.4, ease: 'power3.out' })
                gsap.to(this.textElement, { color: this.props_text.unselect, duration: 0.4, ease: 'power1.inOut' })
                gsap.to(this.icon, { backgroundColor: this.props_BG.unselect, duration: 0.4, ease: 'power1.inOut' })
                gsap.to(this.background, { opacity: 0, duration: 0.4, ease: 'power1.inOut', onComplete: () => {
                    this.target.classList.remove('is-animating');
                    this.target.classList.remove('selected')
                    this.target.disabled = false;
 
                } })

                
            })

            gsap.delayedCall(0.4, () => {
                this.countDown()
            })
            
        }
    }

    introSelect() {


        gsap.to(this.target, { scale: 1.07, duration: 0.35, ease: 'power2.out' })
        gsap.to(this.target, { scale: 1.0, duration: 0.4, ease: 'power2.out', delay: 0.35 })
        gsap.to(this.textElement, { color: this.props_text.select, duration: 0.4, ease: 'power1.inOut' })
        gsap.to(this.icon, { backgroundColor: this.props_BG.select, duration: 0.4, ease: 'power1.inOut' })
        gsap.to(this.background, { opacity: 1, duration: 0.4, ease: 'power1.inOut', onComplete: () => {
            this.isAnimating = false;
        } })

        this.TEXTANIM.appear()
    }

    introText() {
        this.TEXTANIM.appear()
    }

    countUp(num, i) {
        const _prev = this.count;
        const _target = this.count + 1;
        this.count = _target;

        // console.log('countUp' ,_prev, _target)
        animateCounter(this.TEXTANIM.textArr[0], _prev, _target, 0.4);
    }
    countDown(num, i) {
        const _prev = this.count;
        const _target = this.count - 1;

        this.count = _target;
        // console.log(_prev, _target)
        animateCounter(this.TEXTANIM.textArr[0],_prev, Math.max(0,_target), 0.4);
    }

    addFloatingEffect() {
        // gsap.to(this.target, {
        //     y: "+=" + (Math.random() * 30 - 15),
        //     duration: 2,
        //     ease: "power1.inOut",
        //     repeat: -1,
        //     yoyo: true,
        //     delay: Math.random() * 1.5,
        //   });
      
        //   gsap.to(this.target, {
        //     x: "+=" + (Math.random() * 20 - 10),
        //     duration: 3,
        //     ease: "power1.inOut",
        //     repeat: -1,
        //     yoyo: true,
        //     delay: Math.random() * 1.5,
        //   });

        const index = this.target.getAttribute('data-index');

        const _rotation = [-20, 20, -25, -20];
        const _repeatDelay = [0.1, 0.3, 0.2, 0.1];
        const _duration = [1.3, 1.6, 2.2, 1.8];
      
          gsap.to(this.target, {
            rotation: "+=" + _rotation[index],
            duration: _duration[index],
            ease: "power1.inOut",
            repeat: -1,
            yoyo: true,
            // repeatDelay: _repeatDelay[index]
          });
    }
}