import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

export default class CropImage {
    constructor(src, isInit) {
        this.isUpdating = false;
        this.src = src;
        this.isInit = isInit;
        this.cropLayer = document.querySelector('.layer-generate[data-index="0"] .thumb');
        this.buttonCrop = document.querySelector('.button-generate');
        this.init();
    }
    handleCrop() {
        if (!this.isUpdating) {
          this.updateSize();
        }
      }
    init() {
        const image = document.createElement('img');
        image.src = this.src;
        this.image = image;
        this.cropLayer.appendChild(image);

        this.cropper = new Cropper(image, {
            dragMode: 'move',
            aspectRatio: 3 / 4,
            autoCropArea: 1,
            restore: false,
            guides: false,
            center: true,
            responsive: true,
            highlight: false,
            cropBoxMovable: false,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
            viewMode: 1,
            ready: this.onCropperReady.bind(this),
            crop: this.handleCrop.bind(this)
        });

        if (!this.isInit) {
            this.buttonCrop.disabled = false;
        }
    }

    onCropperReady() {
        try {
            const containerData = this.cropper.getContainerData();
            const imageData = this.cropper.getImageData();
            const imageAspectRatio = imageData.naturalWidth / imageData.naturalHeight;
            const targetAspectRatio = 3 / 4;

            let width, height, left, top;

            // 이미지 비율에 따라 다르게 처리
            if (imageAspectRatio > targetAspectRatio) {
                // 가로가 긴 이미지: 세로를 컨테이너에 맞춤
                height = containerData.height;
                width = height * targetAspectRatio;
                left = (containerData.width - width) / 2;
                top = 0;
            } else {
                // 세로가 긴 이미지: 가로를 컨테이너에 맞춤
                width = containerData.width;
                height = width / targetAspectRatio;
                left = 0;
                top = (containerData.height - height) / 2;
            }

            const cropBoxData = {
                width: width,
                height: height,
                left: left,
                top: top
            };

            // 크롭 박스 설정
            this.cropper.setCropBoxData(cropBoxData);

            // 캔버스 설정
            const canvasWidth = imageAspectRatio > targetAspectRatio 
                ? height * imageAspectRatio 
                : containerData.width;
            const canvasHeight = imageAspectRatio > targetAspectRatio 
                ? containerData.height 
                : width / imageAspectRatio;

            this.cropper.setCanvasData({
                width: canvasWidth,
                height: canvasHeight,
                left: (containerData.width - canvasWidth) / 2,
                top: (containerData.height - canvasHeight) / 2
            });
        } catch (error) {
            console.error('Cropper initialization failed:', error);
        }
    }

    handleCrop() {
        this.updateSize();
    }

    updateSize() {
        if (this.isUpdating) return;
        this.isUpdating = true; // Set flag
        
        const containerData = this.cropper.getContainerData();
        const imageData = this.cropper.getImageData();
        const imageAspectRatio = imageData.naturalWidth / imageData.naturalHeight;
        const targetAspectRatio = 3 / 4;

        let width, height, left, top;

        if (imageAspectRatio > targetAspectRatio) {
            // 가로가 긴 이미지: 세로를 컨테이너에 맞춤
            height = containerData.height;
            width = height * targetAspectRatio;
            left = (containerData.width - width) / 2;
            top = 0;
        } else {
            // 세로가 긴 이미지: 가로를 컨테이너에 맞춤
            width = containerData.width;
            height = width / targetAspectRatio;
            left = 0;
            top = (containerData.height - height) / 2;
        }

        this.cropper.setCropBoxData({
            width: width,
            height: height,
            left: left,
            top: top
        });

        this.isUpdating = false; // Reset flag
    }

    destroy() {
        if (this.cropper) {
            this.cropper.destroy();
        }
        if (this.image) {
            this.image.remove();
        }
        if (this.buttonCrop) {
            this.buttonCrop.disabled = true;
        }
    }

    getCroppedImage() {
        return this.cropper.getCroppedCanvas({
            width: 300,
            height: 400
        });
    }
}