import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { floatingCreditExhausted, floatingServiceHoldButton } from './utils';
import ButtonGlow from './ButtonGlow';
import ScrollAnim from './ScrollAnim';
import Swiper from 'swiper';
import TextTranslate from './TextTranslate';
import { Pagination, Autoplay } from 'swiper/modules';


export default class PG_Index {
    constructor(app) {
        this.app = app;
        gsap.registerPlugin(ScrollTrigger);
    
        this.scrollAnim = new ScrollAnim(this, true);
        this.container = document.querySelector('.item-container');
        this.TEXTANIM_TITLE = new TextTranslate(document.querySelector('.layer-list .layer-title .title'), 'words');
        this.TEXTANIM_SUB = new TextTranslate(document.querySelector('.layer-list .layer-title .sub'), 'lines');
    
        this.isGridView = false;
        this.isInit = false;
        this.elementsState = [];

        this.initSplash = false;

        this.Random_INDEX = 6;
        this.Random_CURRENT = 0;
    
        this.init();

        if (document.querySelector('.button-info-guide')) {
            document.querySelector('.button-info-guide').addEventListener('click' ,() => {
                const layer = document.querySelector('.layer-use-guide');

                if (layer.classList.contains('is-selected')) {
                    this.disappearGuide()
                } else {
                    this.appearGuide()
                }
            })

            document.querySelector('.layer-use-guide .button-close-layer').addEventListener('click', () => {
                this.disappearGuide()
            })
        }

        window.addEventListener('resize', () => {
            this.resizeText()
        })
        
        this.initSwiper()
    }

    resizeText() {
        if (document.querySelector('.layer-list .item-image')) {

            const swiperSize = document.querySelector('.layer-list .item-image').clientWidth;
            const titleSize = Math.max(18, Math.floor(swiperSize * 0.08))
            document.documentElement.style.setProperty('--swiperTitle', `${titleSize}px`)
            
            const subSize = Math.max(11, Math.floor(swiperSize * 0.04))
            document.documentElement.style.setProperty('--swiperSub', `${subSize}px`)
        }
    }
    initSwiper() {

        this.swiperTEXTANIM = [];

        gsap.utils.toArray('.swiper-slide').forEach((swiper, index) => {
            const TEXTANIM_TITLE = new TextTranslate(swiper.querySelector('.layer-title .title'), 'words');
            const TEXTANIM_TITLE2 = new TextTranslate(swiper.querySelector('.layer-title .title.t2'), 'words');
            const TEXTANIM_SUB = new TextTranslate(swiper.querySelector('.layer-title .sub'), 'lines');


            this.swiperTEXTANIM[index] = {swiper: swiper, TEXTANIM_TITLE, TEXTANIM_TITLE2, TEXTANIM_SUB}
        
        })
        // console.log(this.swiperTEXTANIM)

        Swiper.use([Pagination, Autoplay])

        this._swiper = new Swiper(document.querySelector(".swiper"), {
            slidesPerView: 1.3,
            centeredSlides: true,
            spaceBetween: 10,
            // loop: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: true
            },
            // speed: 1200,
            // navigation: {
            //     nextEl: container.querySelector(".application-swiper-button-next"),
            //     prevEl: container.querySelector(".application-swiper-button-prev"),
            // },
            pagination: {
                el: document.querySelector('.swiper-pagination')
            }
        });

        this._swiper.autoplay.stop()

        this._swiper.on('slideChangeTransitionStart', (e) => {
            // console.log(e);
            this.changeSwiper(e.activeIndex)
        })

        const calcSize = () => {
            const swiperSize = document.querySelector('.swiper-slide').clientWidth;
            const titleSize = Math.max(18, Math.floor(swiperSize * 0.08))
            document.documentElement.style.setProperty('--swiperTitle', `${titleSize}px`)

            const subSize = Math.max(11, Math.floor(swiperSize * 0.04))
            document.documentElement.style.setProperty('--swiperSub', `${subSize}px`)
        }

        this._swiper.on('init', () => {
            calcSize()
        })

        this._swiper.on('resize', (e) => {
            calcSize()
        })

        this._swiper.on('slideChangeTransitionEnd', (e) => {
            // console.log('stop index' , e);
            this.stopSwiper(e.previousRealIndex)
        })


        const _layer = document.querySelector('.section-swiper');

        gsap.set(_layer, { x: '35%' })
    }

    changeSwiper(index) {

        
        if (this.swiperTEXTANIM[index].swiper.querySelector('video').paused) {
            const video = this.swiperTEXTANIM[index].swiper.querySelector('video')
            if (video) { // 비디오 요소가 존재하는지 확인
                if (video.paused) {
                    video.play()
                        .then(() => {
                            if (!this.app.lowMode) {
                                video.addEventListener('suspend', () => {
                                    this.app.lowMode = true;
                                    this.app.settingLowMode();
                                }, { once: true });
                            }
                        })
                        .catch(error => {
                        });
                }
            }
        }

        if (!this.swiperTEXTANIM[index].swiper.dataset.loaded) {

            // console.log('to ',{index})
            this.swiperTEXTANIM[index].swiper.dataset.loaded = true;

            const TEXTANIM_TITLE = this.swiperTEXTANIM[index].TEXTANIM_TITLE;
            const TEXTANIM_TITLE2 = this.swiperTEXTANIM[index].TEXTANIM_TITLE2;
            const TEXTANIM_SUB = this.swiperTEXTANIM[index].TEXTANIM_SUB;
    
    
    
            gsap.delayedCall(0.1 , () => {
                TEXTANIM_TITLE.appear(0)
                TEXTANIM_TITLE2.appear(0, 0.08)
                TEXTANIM_SUB.appear(0, 0.45)
            })

            if (this.swiperTEXTANIM[index].swiper.querySelector('.icon')) {
                const icon = this.swiperTEXTANIM[index].swiper.querySelector('.icon')
    
                // gsap.delayedCall(0.67 , () => {
                //     gsap.to(icon, { y: '-10%', duration: 0.25, ease: 'power2.out' })
                //     gsap.to(icon, { y: '0%', duration: 0.45, ease: 'back.out', delay: 0.25 })
                //     gsap.to(icon, { x: '10%', duration: 0.25, ease: 'power2.out' })
                //     gsap.to(icon, { x: '0%', duration: 0.45, ease: 'back.out', delay: 0.25 })
                // })
            }
        }




   
 

    }


    stopSwiper(index) {
        if (!this.swiperTEXTANIM[index].swiper.querySelector('video').paused) {
            this.swiperTEXTANIM[index].swiper.querySelector('video').pause()
        }
    }

    initSplash() {
        const layer = document.querySelector('.layer-intro');
        const logo = layer.querySelector('.splash-logo .inner');
        const sub = layer.querySelector('.splash-sub')
        const footer = layer.querySelector('.splash-footer');
        const button = layer.querySelector('.button-glow');
        const nextFloating = document.querySelector('.floating-ui');
        const nextFloatingButton = nextFloating.querySelector('.button-glow');
        const nextFloatingSub = nextFloating.querySelector('.sub');
        const background = document.querySelector('.bridge-background');

        gsap.set(button, {
            autoAlpha: 0
        })

        gsap.set(nextFloatingButton, { 
            opacity: 0,
            y: '100%'
        })

        gsap.set(nextFloatingSub, {
            opacity: 0 
        })

    }
    
    appearSwiper() {
        const layer = document.querySelector('.section-swiper');
        const pagination = document.querySelector('.swiper-pagination');
        const introButton = new ButtonGlow(document.querySelector('.button-glow'));

        document.querySelector('.swiper-slide video').play()
        gsap.set(layer, { display: 'flex' })
        gsap.to(layer, {
            autoAlpha: 1,
            duration: 0.7, 
            ease: 'power1.inOut',
        })
        gsap.to(pagination, {
            autoAlpha: 1,
            duration: 0.7, 
            ease: 'power1.inOut',
        })

        gsap.to(layer, {
            x: 0,
            duration: 0.9, 
            ease: 'power2.out',
            delay: 0,
            onComplete: () => {
                this.changeSwiper(0)

                this._swiper.autoplay.start()
            }
        })
        gsap.delayedCall(1.2, () => {
            introButton.anim()
            gsap.set(document.querySelector('.button-glow'), { pointerEvents: 'auto' })
        })
    }

    appearSplash() {
        const layer = document.querySelector('.layer-intro');
        const logo = layer.querySelector('.splash-logo .inner');
        const logoGradient = logo.querySelector('.logo-gradient');
        const logoSvg = layer.querySelector('.splash-logo .logo-svg');
        const sub = layer.querySelector('.splash-sub')
        const footer = layer.querySelector('.splash-footer');
        const introButtons = document.querySelector('.floating-ui');
        const background = document.querySelector('.bridge-background');
        const button = layer.querySelector('.button-glow');
        

        const centerSection = document.querySelector('.center-section');

        gsap.to(background, {
            y: '0%',
            duration: 1,
            ease: 'power1.out'
        })
   
        gsap.to(footer, {
            autoAlpha: 1,
            duration: 0.7,
            ease: 'power1.inOut',
            delay: 1.4
        })
        gsap.to(logoSvg, {
            opacity: 1, 
            duration: 0.5, 
            ease: 'power1.inOut',
            delay: 0.9
        })
        gsap.to(logo, {
            scale: 0.95,
            duration: 1.2, 
            ease: 'power2.out',
            delay: 0.9
        })
        gsap.to(logo, {
            y: '0%',
            duration: 1.2, 
            ease: 'power2.out',
            delay: 0.7
        })
        gsap.to(logoGradient, {
            opacity: 1, 
            duration: 1.8,
            ease: 'power3.inOut',
            delay: 0.5,
            onComplete: () => {
                gsap.delayedCall(0.1, () => {
                    this.getDATA()
                })

                gsap.delayedCall(0.35, () => {
                    gsap.to(centerSection, { 
                        autoAlpha: 0,
                        display: 'none',
                        duration: 0.65, 
                        ease: 'power1.inOut',
                        onComplete:() => {
                            gsap.delayedCall(0, () => {
                                this.appearSwiper()
                            })
                        }
                    })
                })
                // gsap.to(logoGradient, {
                //     scale: 3,
                //     rotate: '-20deg',
                //     repeat: -1,
                //     yoyo: true,
                //     duration: 4
                // })
            }
        })

     

        if (!sessionStorage.getItem('yepp-appearSplash')) {
            this.initSplash = true;
            sessionStorage.setItem('yepp-appearSplash', "true");
        } else {
            this.initSplash = false;
        }

    }


    disappearSplash() {
        const layer = document.querySelector('.layer-intro');
        const logo = layer.querySelectorAll('.center-section');
        const footer = layer.querySelector('.splash-footer');
        const button = layer.querySelector('.button-glow');
        const nextFloating = document.querySelector('.floating-ui');
        const nextFloatingButton = nextFloating.querySelector('.button-glow');
        const nextFloatingSub = nextFloating.querySelector('.sub');

        const background = document.querySelector('.bridge-background');

        gsap.to(logo, {
            opacity: 0,
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0.0
        })

        gsap.to(footer, {
            opacity: 0,
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0.2
        })

        gsap.to(button, {
            y: '120%',
            duration: 0.7,
            ease: 'power2.out',
            delay: 0.2
        })
        gsap.to(button, {
            opacity: 0,
            duration: 0.6,
            ease: 'power2.out',
            delay: 0.1
        })


        gsap.to([background, layer], { 
            autoAlpha: 0,
            duration: 0.6,
            ease: 'power2.inOut',
            delay: 0.7,
            onComplete: () => {
                gsap.set(layer, { display: 'none' })
                gsap.delayedCall(0.05, () => {
                    this.appearIntro()
                })
            }
        })


        return;
        gsap.to(nextFloating, { 
            opacity: 1,
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0.4,
        })
        gsap.to(nextFloatingButton, { 
            opacity: 1,
            duration: 0.8,
            ease: 'power1.inOut',
            delay: 0.4,
            onStart: () => {
            }
        })
        

        gsap.to(nextFloatingButton, { 
            y: '0%',
            duration: 0.8,
            ease: 'power2.out',
            delay: 0.4,
            onStart: () => {
            }
        })

        gsap.to(nextFloatingSub, { 
            opacity: 1,
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 1.0,
        })
    }

    async init() {
        this.updateTime()
        this.bindEvents();
        // 서비스 상태 체크
 
    
        const isLoggedIn = await this.app.isLoggedIn();
        if (isLoggedIn) {

            const isActive = await this.app.getServiceStatus();
            if (!isActive) {

                const content = floatingServiceHoldButton();

                document.querySelector('.floating-ui').appendChild(content)
       
            }

            const is_credit_exhausted = await this.app.getCredit();

            if (is_credit_exhausted && is_credit_exhausted.is_credit_exhausted) {

                const content = floatingCreditExhausted()
                document.querySelector('.floating-ui').appendChild(content)
            }
    

        } else {
    
        }

    
        // 기존 초기화 로직
        if (!sessionStorage.getItem('yepp-appearSplash')) {
            this.initSplash = true;
          this.appearSplash();
        } else {
            gsap.set('.layer-intro', { backgroundColor: 'transparent' });
            this.getDATA('/generate/images');
            gsap.delayedCall(0.3, () => this.appearIntro());
        }

    }



    bindEvents() {
        const buttonSplash = document.querySelector('.button-hidden-splash');
        if (buttonSplash) {
          buttonSplash.addEventListener('click', () => {
            buttonSplash.disabled = true;
            this.disappearSplash();
          });
        }
    
        const generateButton = document.querySelector('.button-move-generate');
        if (generateButton) {
          generateButton.addEventListener('click', () => {
            if (document.body.classList.contains('not-loggedIn')) {

                window.location.href = '/login';
            } else {
                
                window.location.href = '/generate'
            }
          });
        }
    
      }


    appearIntro() {
        // console.log('appearIntro')
        gsap.set('.layer-intro', { display: 'none' })
        gsap.set('.bridge-background', { display: 'none' })
        
        this.TEXTANIM_TITLE.appear(0)
        this.TEXTANIM_SUB.appear(0, 0.2)
        
        document.body.classList.remove('is-lock')
        const nextFloating = document.querySelector('.floating-ui');

        const nextButton = nextFloating.querySelector('.button-glow');

        const buttonGuide = document.querySelector('.button-info-guide');

        const _glow = new ButtonGlow(nextButton);
        _glow.complete()

        gsap.to(nextFloating, {
            autoAlpha: 1, 
            duration: 0.85, 
            ease: 'power1.out',
            delay: 0.2,
            onComplete: () => {
       
            }
        })
        
        gsap.to(nextFloating, {
            y: '0%',
            duration: 0.85, 
            ease: 'power2.out',
            delay: 0.2,
            onComplete: () => {
                
            }
        })

       
        gsap.to(buttonGuide, { autoAlpha: 1, duration: 0.6, ease: 'power1.out', delay: 0.8 })
        gsap.to(buttonGuide.querySelector('.icon'), { y: '0%', duration: 0.6, ease: 'back.out', delay: 0.8, onComplete: () => {
            if (this.initSplash) {
                gsap.delayedCall(0.01, () => {
                    this.appearGuide()
                })
            }
        } })
    }

    updateDATA() {
        if (this.next) {
            this.getDATA(this.next);
        } else {
            this.observer.disable(true)
        }
    }

    changeFilter(_callback) {
        const _items = gsap.utils.toArray('.item-image', this.container);

        
        gsap.to('.view', {
            opacity: 0, 
            duration: 0.25, 
            ease: 'power1.inOut',
            onComplete: () => {
                _items.forEach(item => item.remove())
                gsap.to('.view', {
                    opacity: 1,
                    duration: 0.3,
                    ease: 'power1.inOut',
                    onComplete: () => {
                        if (_callback) {
                            _callback()

                        }
                    }
                })
           
            }
        })
    }
    createItemPromotion(overallPosition) {

        const bannerItem = [
            `<div class="thumb-bg">
                <img src="/assets/video/frame-main.png" alt="">
            </div>
            <div class="thumb">
                <img src="/assets/video/onboarding_3_preview.png" />
                <video src="/assets/video/Onboard_3.webm" preload playsinline loop muted></video>
            </div>

            <div class="thumb-text">

                <div class="layer-title">
                    <div class="title text-container" style="--color: #DFFAFF;">
                        <span class="text-content">AI가 선정하는</span>
                    </div>
                    <div class="title text-container t2" style="--color: #DFFAFF;">
                        <span class="text-content">오픈 이벤트</span>
                    </div>
                    <div class="sub text-container without-y">
                        <span class="text-content">카드를 완성하면 자동 참여!<br />AI가 직접 선정하고 발표할 예정이에요</span>
                    </div>
                </div>
            </div>
            
            <span class="icon icon-arrow_outward"></span>
            <a class="hidden-link" href="https://www.instagram.com/reel/DHVfg4Qzt-z/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" target="_blank" data-gtm-id="listPromoIG"></a>`,
            `<div class="thumb-bg">
                <img src="/assets/video/frame-main.png" alt="">
            </div>
            <div class="thumb">
                <img src="/assets/video/onboarding_4_preview.png" />
                <video src="/assets/video/Onboard_4.webm" preload playsinline loop muted></video>
            </div>

            <div class="thumb-text">

                <div class="layer-title">
                    <div class="title text-container">
                        <span class="text-content">Behind</span>
                    </div>
                    <div class="title text-container t2">
                        <span class="text-content">The Scenes</span>
                    </div>
                    <div class="sub text-container without-y">
                        <span class="text-content">PlusX의 YEPP 제작 과정과<br />우여곡절을 알아봐요</span>
                    </div>
                </div>
            </div>
            <span class="icon icon-arrow_outward"></span>
            <a class="hidden-link" href="https://brunch.co.kr/@plusx/126" target="_blank" data-gtm-id="listPromoBrnh"></a>`,
            ` <div class="thumb-bg">
                <img src="/assets/video/frame-main.png" alt="">
            </div>
            <div class="thumb">
                <img src="/assets/video/onboarding_2_preview.png" />
                <video src="/assets/video/Onboard_2.webm" preload playsinline loop muted></video>
            </div>

            <div class="thumb-text">
            <div class="layer-title">
                <div class="title text-container">
                    <span class="text-content">멋져요!</span>
                </div>
                <div class="title text-container t2">
                    <span class="text-content">함께하는 즐거움</span>
                </div>
                <div class="sub text-container without-y">
                    <span class="text-content">다양한 감정 표현이 담긴<br />스티커를 서로 남겨보세요</span>
                </div>
            </div>`,
            `<div class="thumb-bg">
                <img src="/assets/video/frame-main.png" alt="">
            </div>
            <div class="thumb">
                <img src="/assets/video/onboarding_1_preview.png" />
                <video src="/assets/video/Onboard_1.webm" preload playsinline loop muted></video>
            </div>

            <div class="thumb-text">
                <div class="layer-title">
                    <div class="title text-container">
                        <span class="text-content">AI가 말아주는</span>
                    </div>
                    <div class="title text-container t2">
                        <span class="text-content">키라키라</span>
                    </div>
                    <div class="sub text-container without-y">
                        <span class="text-content">AI로 사진에 별빛을 퍼뜨리면서<br />우연한 감성을 담아보세요</span>
                    </div>
                </div>
            </div>`

        ]
        const content = document.createElement('div');
        content.classList.add('item-image')
        content.classList.add('item-promote')

        content.innerHTML = bannerItem[this.Random_CURRENT];

        
        
        // 현재 컨테이너의 모든 자식(즉, item-image)들을 가져옴
        const allItems = this.container.querySelectorAll('.item-image');
        
        // 실제 DOM 인덱스(0-based)로 변환
        // overallPosition=7 → index=6
        const domIndex = overallPosition - 1;

        // 만약 현재 전체 아이템 수보다 작거나 같으면(즉, 중간에 끼울 수 있으면)
        // 그 위치에 insertBefore
        if (domIndex < allItems.length) {
            this.container.insertBefore(content, allItems[domIndex]);
        } else {
            // 이미 전체 아이템보다 뒤라면 그냥 맨 뒤에 붙임
            this.container.appendChild(content);
        }
        this.Random_CURRENT++;
        if (this.Random_CURRENT >= bannerItem.length) {
            this.Random_CURRENT = 0;
        }

        const TEXTANIM_TITLE = new TextTranslate(content.querySelector('.layer-title .title'), 'words');
        const TEXTANIM_TITLE2 = new TextTranslate(content.querySelector('.layer-title .t2'), 'words');
        const TEXTANIM_SUB = new TextTranslate(content.querySelector('.layer-title .sub'), 'lines');

        const appearAnim = () => {

            if (content.querySelector('video').paused) {

                const video = content.querySelector('video');
                if (video) { // 비디오 요소가 존재하는지 확인
                    if (video.paused) {
                        video.play()
                            .then(() => {
                                if (!this.app.lowMode) {
                                    video.addEventListener('suspend', () => {
                                        this.app.lowMode = true;
                                        this.app.settingLowMode();
                                    }, { once: true });
                                }
                            })
                            .catch(error => {
                            });
                    }
                }
            }
            content.querySelector('video').play();
            TEXTANIM_TITLE.appear(0)
            TEXTANIM_TITLE2.appear(0, 0.08)
            TEXTANIM_SUB.appear(0, 0.45)
        }


        ScrollTrigger.create({
            trigger: content,
            start: () => `top bottom`,
            end: () => `90% top`,
            refreshPriority: 0,
            onToggle: (self) => {
                if (self.isActive) {
                    if (content.querySelector('video').paused) {
                        const video = content.querySelector('video');
                        if (video) { // 비디오 요소가 존재하는지 확인
                            if (video.paused) {
                                video.play()
                                    .then(() => {
                                        if (!this.app.lowMode) {
                                            video.addEventListener('suspend', () => {
                                                this.app.lowMode = true;
                                                this.app.settingLowMode();
                                            }, { once: true });
                                        }
                                    })
                                    .catch(error => {
                                    });
                            } else {
                            }
                        }
                    }

       
                      
                } else {
                    if (content.querySelector('video') && !content.querySelector('video').paused) {
                        content.querySelector('video').pause()
                    }
                }
            }
        })

        ScrollTrigger.create({
            trigger: content.querySelector('.layer-title'),
            start: () => `2% bottom`,
            end: () => `90% top`,
            once : true,
            onEnter: () => {
                if (!content.dataset.load) {
                    content.setAttribute('data-load', true)
                    appearAnim()
                }
            },
        })
    }
    async getDATA(_url) {
        try {

            let url = _url ?? '/generate/images';

            if (!url.includes('ordering') && this.ordering) {
                url += `?ordering=${this.ordering}`;
            } 

            if (this.ordering == null) {
                // console.log(url.split('?ordering=')[0])
            }

            // console.log({url})

            
            const respose = await this.app.getImages(url)

            const { results, next } = respose.data;
   
            if (results) {
          
                results.forEach((itemData, index) => {
                    this.createItem(itemData);

                    if (!this.initSize && document.querySelector('.layer-list .item-image')) {
                        this.initSize = true;
                        this.resizeText()
                    }
                });
            
    
                function replaceNumberWithPlaceholder(url) {
                    return url.replace(/\/accounts\/\d+\//, `/accounts/\${id}/`);
                }
                
                if (results[0].created_by) {
                    // 사용 예시
                    const originalUrl = results[0].created_by.user_profile_url;

                    const updatedUrl = replaceNumberWithPlaceholder(originalUrl);
                    this.app.accountApi = updatedUrl;
                }
 
            }

            if (next) {
                this.next = next.split('com/api')[1];
            } else {
                this.next = null;
            }

            if (!this.isInit) {
                this.scrollAnim.scrollTransition()
            }
            this.scrollObserver()
        } catch(error) {
            // console.log(error)
        }
    }


    createItem(data) {

        const { id, generative_image, generative_image_thumbnail, total_reaction_count, recent_reactions, created_by } = data;

        let created = {
            name: 'username',
            team_name: 'team_name'
        }

        if (data.created_by) {

            const { name, team_name } = data.created_by;

            created.name = name;
            created.team_name = team_name;
            
        }

        const content = document.createElement('div');
        content.classList.add('item-image')
        // content.classList.add('crop')

        content.style.setProperty('--ox', this.opacityValue);

        content.innerHTML = `
            <div class="thumb">
                <img src=${generative_image_thumbnail} alt="${id}">
            </div>
            <div class="information-deck" data-has="${data.has_reaction_user}">
                <div class="tag tag-reaction">
                    <div class="tag-leading tag-reactions"></div>
                    <div class="tag-trailing tag-count"></div>
                </div>
                <div class="tag tag-userinfo">
                    <span class="tag-leading">
                        <span class="tag-text tag-name">${created.name}</span>
                    </span>
                    
                </div>
            </div>
            <a class="hidden-link" href="/image?id=${id}"></a>
        `;


        //           <span class="symbol "><img src="/assets/reactions/reaction-symbol-${reaction.name}.svg" /></span>`
        if (recent_reactions.length > 0) {
            content.querySelector('.tag-reactions').innerHTML = 
                `${recent_reactions.map((reaction, i) => `
                    <span class="symbol symbol-${reaction.name}"></span>`
                )
                .join('')}`


        
            content.querySelector('.tag-count').innerHTML = `<span class="tag-text">${total_reaction_count}</span>`
        } else {
      
            content.querySelector('.tag-reactions').innerHTML = `<span class="symbol"><img src="/assets/icon/icon-reaction-empty.png" /></span>`
        }

        


        content.addEventListener('click', this._initClick)

        content.querySelector('img').onload = function(){
            gsap.to(content.querySelector('.information-deck'), { opacity: 1, duration: 0.8, ease: 'power1.inOut' })
            gsap.to(content.querySelector('img'), { opacity: 1, duration: 0.8, ease: 'power1.inOut' })
        };
        this.scrollAnim.initElementState()
        this.container.appendChild(content);

        const initialLength = this.container.querySelectorAll('.item-image').length;


        // console.log({initialLength})

        if (initialLength % 7 === 0) {
            this.createItemPromotion(initialLength)

            // console.log(initialLength, 'create banner')
        }
        
    }


    scrollObserver() {
        if (this.observer) {
            this.observer.refresh();
            this._count = this._count + 1;
            return;
        }
        this.observer = ScrollTrigger.create({
            // scroller: '.app',
            trigger: '.item-container',
            start: () => `70% bottom`,
            markers: false,
            onEnter: () => {
                // console.log('load more', this._count)
                this.updateDATA()
                // gsap.set(document.body, { opacity: 0s.7 })
            }
        })
    }

    
    appearGuide() {
        const layer = document.querySelector('.layer-use-guide');
        const inner = layer.querySelector('.layer-inner');
        const text = layer.querySelectorAll('.text.-show');
        const sub = inner.querySelector('.info-text');
        const bg = layer.querySelector('.background')
        if (layer.classList.contains('is-selected')) return;
        layer.classList.add('is-selected')

        document.body.classList.add('is-lock')

        gsap.killTweensOf([inner, text, sub, bg], 'all')
        
        gsap.set(layer, { display: 'flex' })

        

        gsap.to(bg, {
            opacity: 1,
            duration: 0.35,
            ease: "power1.inOut",
            delay: 0,
            onComplete: () => {
                // disableScroll();
            },
        });
        // gsap.to(text, {
        //     height: '100%',
        //     duration: 1.0,
        //     ease: "power1.out",
        //     delay: 0.3,
        // })
        gsap.to(text, {
            opacity: 1,
            duration: 0.8,
            ease: "power1.inOut",
            delay: 0.3,
            stagger: 0.1
        })
        gsap.to(inner, {
            opacity: 1,
            duration: 0.35,
            ease: "power1.inOut",
            delay: 0.2,
            onComplete: () => {
                // disableScroll();
            },
        });
        gsap.to(sub, {
            opacity: 1,
            duration: 0.8,
            ease: "power1.inOut",
            delay: 1.0,
            onComplete: () => {
                // disableScroll();
            },
        });

    }

    disappearGuide() {
        const layer = document.querySelector('.layer-use-guide');
        const inner = layer.querySelector('.layer-inner');
        const text = layer.querySelectorAll('.text.-show');
        const bg = layer.querySelector('.background')
        const buttonGuide = document.querySelector('.button-info-guide');
        const sub = inner.querySelector('.info-text');

        gsap.killTweensOf([inner, text, sub, bg], 'all')
        
        gsap.to(bg, {
            opacity: 0,
            duration: 0.3,
            ease: "power1.inOut",
            delay: 0.1,
            onComplete: () => {
                // disableScroll();
                layer.classList.remove('is-selected')
                gsap.set(layer, { display: 'none' })
                gsap.set([text, sub], { opacity: 0 })
                document.body.classList.remove('is-lock')

           
                gsap.delayedCall(0.0, () => {
                    gsap.to(buttonGuide.querySelector('.icon'), { y: '-40%', duration: 0.25, ease: 'power2.out' })
                    gsap.to(buttonGuide.querySelector('.icon'), { y: '0%', duration: 0.45, ease: 'back.out', delay: 0.25 })
            
                })
            },
        });

        gsap.to(inner, {
            opacity: 0,
            duration: 0.35,
            ease: "power1.inOut",
            delay: 0,
            onComplete: () => {
                layer.querySelector('.item-inner').scrollTo(0, 0)
            },
        });
    }
    updateTime() {
        function getTimeUntilTarget() {
            const now = new Date();
            const kstOffset = 9 * 60 * 60 * 1000;
            const utcNow = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
            const kstNow = new Date(utcNow + kstOffset);
            const target = new Date("2025-04-01T00:00:00+09:00");
        
            const timeDiff = target - kstNow;
            const hours = Math.floor(timeDiff / (1000 * 60 * 60));
           
            if (timeDiff <= 0) {
                return 'end';
            }
            return `${hours}`;
        }
    
        const result = getTimeUntilTarget();
        if (result === "end") {
            document.querySelector('.time-noti').innerHTML = `오픈 이벤트가 마감되었어요`;
        } else {
            document.querySelector('.time-noti').innerHTML = `이벤트 마감 ${result}시간 전!`;
        }
    }
}

