

import axios from "axios";
import gsap from 'gsap';


const apiClient = axios.create({
    baseURL: process.env.API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

window.apiClient = apiClient;

const activeRequests = new Map();

apiClient.interceptors.request.use((config) => {
    const controller = new AbortController();
    config.signal = controller.signal;

    const requestKey = config.url;
    if (activeRequests.has(requestKey)) {
        const oldController = activeRequests.get(requestKey);
        oldController.abort();
    }
    activeRequests.set(requestKey, controller);
    return config;
});

apiClient.interceptors.response.use(
    (response) => {
        const requestKey = response.config.url;
        activeRequests.delete(requestKey);
        return response;
    },
    (error) => {
        if (axios.isCancel(error)) {
            // console.log('Request canceled:', error.message);
        } else if (error.code === 'ECONNABORTED') {
            // console.error('Request timed out:', error.message);
        }
        const requestKey = error.config?.url;
        activeRequests.delete(requestKey);
        return Promise.reject(error);
    }
);

export default class App {
    constructor() {
        this.yepp_accessToken = localStorage.getItem('prd_yepp_accessToken') || null;
        this.yepp_refreshToken = localStorage.getItem('prd_yepp_refreshToken') || null;
        this.id = 0;
        this.credit = null;
        this.isActive = null;
        this.isLoggedInStatus = null; // 로그인 상태 캐싱
        this.accountData = null; // getAccountMy 결과 캐싱
    
        // ... 기존 constructor 코드 ...
        this.initSocial();
        this.bindEventListeners();
      }
    bindEventListeners() {
        gsap.utils.toArray('.button-account').forEach(b => {
            b.addEventListener('click', this.handleClick.bind(this));
        });

        gsap.utils.toArray('.button-reaction').forEach(b => {
            b.addEventListener('click', this.handleReactionClick.bind(this));
        });
    }

    async handleReactionClick(event) {
        const button = event.currentTarget;

        const validSession = await this.validateSession();

        if (!validSession) {
            this.redirectToLogin();
            return;
        } else {
            this.getAccountMy();
        }
    }


    async handleClick(event) {
        const button = event.currentTarget;

        if (button.hasAttribute('data-href')) {
            window.location.href = button.dataset.href;
            return;
        }

        const validSession = await this.validateSession();

        if (!validSession) {
            this.redirectToLogin();
            return;
        } else {
            this.getAccountMy();
        }
    }
      async init() {
        this.setupInterceptors();
        const currentPath = window.location.pathname;
    
        // 로그인 상태 확인 및 캐싱
        this.isLoggedInStatus = await this.isLoggedIn();
        this.initGAEvents(this.isLoggedInStatus)
        if (['/generate'].some(path => currentPath.startsWith(path))) {
          const validSession = await this.validateSession();
          if (!validSession) {
            this.redirectToLogin();
            return;
          }
          await this.getAccountMy(); // 한 번만 호출
        }
    
        // 서비스 상태 및 크레딧 로드 (한 번만 호출)
        if (this.isLoggedInStatus) {
            await this.fetchAndUpdateServiceStatus();
            await this.fetchAndUpdateCredit();
            await this.getAccountMy()
        }

      }
        // 이메일 인증번호 요청
        async requestEmailVerification(email) {
            try {
                const response = await axios.post(
                    `${process.env.API_BASE_URL}/accounts/email`,
                    { email }
                );
                if (response.status === 201) {
                    // console.log('Verification code sent to:', email);
                    return response.data;
                }
                // throw new Error('Failed to send verification code');
            } catch (error) {
                // console.error('Error requesting email verification:', error);
                
                return false;
            }
        }
    
        // 인증번호 확인
        async verifyEmailCode(identify_id, verification_code) {
            try {
                const response = await axios.post(
                    `${process.env.API_BASE_URL}/accounts/verification/${identify_id}`,
                    { verification_code }
                );
                
                if (response && response.status === 200) {
                    // console.clear()
                    this.emailVerified = true;
                    // this.emailVerificationCode = code; // 필요 시 저장
                    return { status : 'success' };
                }
            } catch (error) {
                return { status : 'fail', error: error.response.data?.error };

            }
        }
    
        // 회원가입 (이메일 인증 후 호출)
        async signupWithEmail(identify_id, password, name) {
            if (!this.emailVerified) {
                // console.error('Email verification required before signup');
                return false;
            }
    
            try {
                const response = await axios.post(
                    `${process.env.API_BASE_URL}/accounts/signup/${identify_id}`,
                    { password: password, password2: password, name }
                );
        
                if (response.status === 201) {
                    // const { access, refresh } = response.data;
                    // this.saveTokens(access, refresh); // 토큰 저장
                    // this.setupInterceptors();
        
                    // const redirectPath = localStorage.getItem('redirectAfterLogin') || '/';
                    // localStorage.removeItem('redirectAfterLogin');
                    // window.location.href = redirectPath;
        
                    // console.log('Signup successful:', response.data);
                    return { success: true, message: 'Signup completed successfully' };
                }
            } catch (error) {
                console.error('Error during signup:', error);
                const firstValue = Object.values(error.response.data)[0][0];

                return { success: false, message: firstValue };
            }
        }
    
        // 통합된 회원가입 프로세스 
        async handleSignup(email, password, name, verificationCode) {
            // 1. 인증번호 요청 (UI에서 먼저 호출되면 생략 가능)
            if (!this.emailVerified) {
                const emailSent = await this.requestEmailVerification(email);
                if (!emailSent) {
                    return { success: false, message: 'Failed to send verification code' };
                }
    
                // 2. 인증번호 확인
                const verified = await this.verifyEmailCode(email, verificationCode);
                if (!verified) {
                    return { success: false, message: 'Invalid verification code' };
                }
            }
    
            // 3. 회원가입 요청
            const signupSuccess = await this.signupWithEmail(email, password, name);
            if (!signupSuccess.success) {
                // console.log('signup ', signupSuccess);
                return { success: false, message: signupSuccess.message };
            }
    
            return { success: true, message: 'Signup completed successfully' };
        }
      async isLoggedIn() {
        if (this.isLoggedInStatus !== null) {
          return this.isLoggedInStatus; // 캐싱된 결과 재사용
        }
    
        if (!this.yepp_accessToken) return false;
    
        try {
          const response = await axios.post(`${process.env.API_BASE_URL}/accounts/token/verify`, {
            token: this.yepp_accessToken,
          });
          this.userLoggedIn = true;
          this.isLoggedInStatus = response.status === 200;

          return this.isLoggedInStatus;
        } catch (error) {
            this.logout()
        //   console.error('Token validation failed:', error);
          this.userLoggedIn = false;
          this.isLoggedInStatus = false;
          return false;
        }
      }
    
    //   async getAccountMy() {
    //     if (this.accountData) {
    //       return this.accountData; // 캐싱된 결과 재사용
    //     }
    
    //     if (!(await this.isLoggedIn())) {
    //       return false;
    //     }
    
    //     try {
    //       const response = await apiClient.get('/accounts/me');
    //       gsap.utils.toArray('.button-account').forEach(b => b.dataset.href = `/account?id=${response.data.id}`);
    //       this.userLoggedIn = true;
    //       this.id = response.data.id;
    //       this.show_user_guide = response.data.show_user_guide;
    //       this.accountData = response.status === 200; // 캐싱
    //       return this.accountData;
    //     } catch (error) {
    //       console.error('getAccountMy failed:', error);
    //       if (error.response.status == 403) {
    //         this.logout();
    //       }
    //       return false;
    //     }
    //   }
    
      async fetchAndUpdateServiceStatus() {
        if (this.isActive !== null) {
          return this.isActive; // 캐싱된 결과 재사용
        }
    
        try {
          const response = await apiClient.get('/server/status');
          if (response && response.status === 200) {
            this.isActive = response.data.is_active;
            // this.updateServiceStatusUI();
            return this.isActive;
          }
          throw new Error('Failed to fetch service status');
        } catch (error) {
        //   console.error('Error fetching service status:', error);
          this.isActive = false;
        //   this.updateServiceStatusUI();
          return false;
        }
      }
    
      async fetchAndUpdateCredit() {
        if (this.credit !== null) {
          return this.credit; // 캐싱된 결과 재사용
        }
    
        try {
          const response = await apiClient.get('/credit');
          if (response && response.status === 200) {
            this.credit = response.data;
            this.credit_exhausted = response.data.is_credit_exhausted;
            return this.credit;
          }
          throw new Error('Failed to fetch credit');
        } catch (error) {
          return null;
        }
      }
    
      async getServiceStatus() {
        return this.isActive !== null ? this.isActive : await this.fetchAndUpdateServiceStatus();
      }
    
      async getCredit() {
        return this.credit !== null ? this.credit : await this.fetchAndUpdateCredit();
      }


    async validateSession() {
        const isValid = await this.isLoggedIn();
        if (!isValid) {
            this.clearTokens();
        }
        return isValid;
    }
    // 로그인 페이지로 리다이렉트
    redirectToLogin() {
        const currentPath = window.location.pathname; // 현재 경로
        if (currentPath !== '/login/') {
            localStorage.setItem('redirectAfterLogin', window.location.href); // 리다이렉트 경로 저장
            window.location.href = '/login/'; // 로그인 페이지로 이동
        }
    }


    // 토큰 저장
    saveTokens(access, refresh) {
        this.yepp_accessToken = access;
        this.yepp_refreshToken = refresh;
        localStorage.setItem('prd_yepp_accessToken', access);
        localStorage.setItem('prd_yepp_refreshToken', refresh);
    }

    // 토큰 삭제
    clearTokens() {
        this.yepp_accessToken = null;
        this.yepp_refreshToken = null;
        localStorage.removeItem('prd_yepp_accessToken');
        localStorage.removeItem('prd_yepp_refreshToken');
    }

    async socialLogin(social_id, social_type) {
        try {
            const response = await axios.post(`${process.env.API_BASE_URL}/accounts/social/login`, {
                social_id
            });

            if (response) {
                // console.log('app socialLogin ', this.userDATA)
                return response;
            }
            
        } catch (error) {
            return error;

        } finally {}
    }

    async login(username, password) {
        try {
            const response = await axios.post(`${process.env.API_BASE_URL}/accounts/token`, {
                username,
                password,
            });

            const {
                access,
                refresh
            } = response.data;

            this.saveTokens(access, refresh); // 토큰 저장

            this.setupInterceptors(); // 인터셉터 설정


            // 로그인 후 리다이렉트 처리
            const redirectPath = localStorage.getItem('redirectAfterLogin') || '/';
            localStorage.removeItem('redirectAfterLogin'); // 저장된 리다이렉트 경로 제거
            window.location.href = redirectPath; // 기존 경로로 이동
        } catch (error) {

            if (document.querySelector('.button-login')) {


                const emailFormatRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            
                if (!emailFormatRegex.test(username)) {
                    document.querySelector('.error').innerHTML = '이메일 형식으로 입력해 주세요';
                } else {
                    document.querySelector('.error').innerHTML = '이메일 또는 비밀번호를 확인해 주세요';
                }

                document.querySelector('form').classList.add('is-error')
                document.querySelector('.button-login').disabled = false;


            }
        }
    }

    async signup() {
        try {
            const { email, social_id, social_type, name } = this.userDATA;
            const response = await axios.post(`${process.env.API_BASE_URL}/accounts/social/signup`, {
                email, social_id, social_type, name
            });

            if (response) {
                console.log('signup ', response)
                const { access, refresh } = response.data;
                this.saveTokens(access, refresh);

                return { success: true, message: 'Signup completed successfully' };
            }
        } catch(error) {

            console.log('error', error)

            const errorText = document.querySelector('.error-text')

            const appearError = () => {
                gsap.to(errorText, {
                    autoAlpha: 1,
                    duration: 0.2,
                    ease: 'power1.out'
                })
            }

            const disappearError = () => {
                gsap.to(errorText, {
                    autoAlpha: 0,
                    duration: 0.2,
                    ease: 'power1.out'
                })
            }

            if (error.response.status) {
                errorText.innerHTML = '사용할 수 없는 닉네임이에요';
                appearError()
            }

            const firstValue = Object.values(error.response.data)[0][0];

            return { success: false, message: firstValue };

        } finally {

        }
    }

    async refreshyepp_accessToken() {
        try {
            const response = await apiClient.post('/accounts/token/refresh', {
                refresh: this.yepp_refreshToken,
            });

            const {
                access,
                refresh
            } = response.data;

            this.saveTokens(access, refresh); // 갱신된 토큰 저장
            // console.log('Access token refreshed:', response.data);
        } catch (error) {
            // console.error('Error refreshing token:', error);
        }
    }

    settingLowMode() {
        this.fps = 30;
        gsap.ticker.fps(this.fps);
    }

    logout() {
        this.clearTokens(); // 세션에서 토큰 제거
        // console.log('Logged out successfully.');
        window.location.href = '/';
        // this.redirectToLogin(); // 로그아웃 후 로그인 페이지로 이동
    }

    setupInterceptors() {
        apiClient.interceptors.request.use((config) => {
            if (this.yepp_accessToken) {
                config.headers.Authorization = `Bearer ${this.yepp_accessToken}`;
                // console.log('Using token:', this.yepp_accessToken);
            }
            return config;
        });

        apiClient.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;

                if (error.response?.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    try {
                        await this.refreshyepp_accessToken();
                        originalRequest.headers.Authorization = `Bearer ${this.yepp_accessToken}`;
                        return apiClient(originalRequest);
                    } catch (refreshError) {
                        console.error('Refresh token expired:', refreshError);
                        this.logout();
                        return Promise.reject(refreshError);
                    }
                }

                return Promise.reject(error);
            }
        );
    }

    async getImages(url) {
        try {

            const _url = url || `/generate/images`;

            if (this.isLoggedIn) {
                const response = await apiClient.get(`${process.env.API_BASE_URL}${_url}`);
                if (response) {
                    return response;
                }
            } else {
                const response = await axios.get(`${process.env.API_BASE_URL}${_url}`);
                if (response) {
                    return response;
                }
            }
    
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    }

    async getDetailData(id) {
        try {
            if (this.isLoggedIn) {
                const response = await apiClient.get(`${process.env.API_BASE_URL}/generate/images/${id}`);
                if (response) {
                    return response.data;
                }
            } else {
                const response = await axios.get(`${process.env.API_BASE_URL}/generate/images/${id}`);
                if (response) {
                    return response.data;
                }
            }
       
        } catch (error) {
            window.location.href = '/404.html';
        }
    }

    async replicateImage(image) {
        try {
            const formData = new FormData();
            formData.append('image', image);

            const response = await axios.post(
                `${process.env.API_BASE_URL}/replicate`,
                formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${this.yepp_accessToken}`,
                    },
                }
            );
            // console.log(response, this.yepp_accessToken);
            return response;
        } catch (error) {
            // console.log(error, image);
        }
    }

    async replicateImage_First(image) {
        try {
          const formData = new FormData();
          formData.append('image', image);
      
          const response = await axios.post(
            `${process.env.API_BASE_URL}/replicate/first`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${this.yepp_accessToken}`,
              },
            }
          );
          return response.data;
        } catch (error) {
          // 크레딧 소진 여부 확인
          if (error.response && error.response.status) {
            return { status: error.response.status };
          }
          return { status: '' };
        }
    }
    async replicateImage_Second(data) {
        try {

            const response = await apiClient.post(
                `/replicate/second`,
                data
            );
            // console.log(response, this.yepp_accessToken);
            return response.data;
        } catch (error) {
            if (error.response && error.response.status) {
                return { status: error.response.status };
            }
        }
    }

    async replicateImage_Third(data) {
        try {

            const response = await apiClient.post(
                `/replicate/third`,
                data
            );
            // console.log(response, this.yepp_accessToken);
            return response.data;
        } catch (error) {
            if (error.response && error.response.status) {
                return { status: error.response.status };
            }
        }
    }

    async generateImage(init_image, generative_image, card_frame) {
        if (!(await this.isLoggedIn())) {
            this.redirectToLogin();
            return; // 로그인되지 않은 경우 메서드 종료
        }
    
        try {
            const formData = new FormData();
            formData.append('init_image', init_image);
            formData.append('generative_image', generative_image);
            formData.append('card_frame', card_frame)
    
            const response = await axios.post(
                `${process.env.API_BASE_URL}/generate`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${this.yepp_accessToken}`,
                    },
                }
            );
            return response;
        } catch (error) {
            console.error('Error generating image:', error);
        }
    }
    
    async getAccountMy() {
        if (!(await this.isLoggedIn())) {
            return false; // 로그인되지 않은 경우 종료
        }
    
        try {
            const response = await apiClient.get('/accounts/me');
            gsap.utils.toArray('.button-account').forEach(b => b.dataset.href = `/account?id=${response.data.id}`);


            this.userLoggedIn = true;
            this.id = response.data.id;
            this.show_user_guide = response.data.show_user_guide;
          
            return response.status === 200;
        } catch (error) {
            console.error('getAccountMy failed:', error);
            if (error.response.status == 403) {
                this.logout()
            }
            return false;
        }
    }
    async createReaction(generate_image, reaction_type) {
        try {
            const response = await apiClient.post(`/reactions`, {
                generate_image,
                reaction_type,
            });

            if (response) { // HTTP 200 Created
                return response.data; // 성공 시 true 반환
            }
            // console.error('Failed to create reaction:', response.status, response.data);
            return false; // 성공하지 않은 경우 false 반환
        } catch (error) {}
    }

    async deleteReaction(id) {
        try {
            const response = await apiClient.delete(`/reactions/${id}`);

            if (response) { // HTTP 200 OK
                // console.log('Reaction deleted successfully:', response.data);
                return true; // 성공 시 true 반환
            }
            // console.error('Failed to delete reaction:', response.status, response.data);
            return false; // 성공하지 않은 경우 false 반환
        } catch (error) {}
    }

    async getAccount(id) {
        try {
            const response = await apiClient.get(`/accounts/${id}/profile`);
            if (response) {
                return response.data;
            }
        } catch (error) {
            window.location.href = '/404.html';
        }
    }

    async deleteImage(id) {
        try {
            const response = await apiClient.delete(`/generate/images/${id}`);

            return response;
        } catch (error) {
            // console.log('error');
        }
    }

    async getCardFrames() {
        try {
            const response = await apiClient.get(`/cardframes`);
            if (response) {
                return response.data;
            }
        } catch (error) {
            // console.log(error);
        }
    }

    async toggleGuide(value) {
        try {
            const response = await apiClient.put(`/accounts/${this.id}/guide`, {
                show_user_guide: value
            });

            if (response) {
                // console.log('가이드 API', response.data)
            }
        } catch (error) {
            // console.log('hidden Guide', error)
        }
    }

    // callApiRepeatedly = async (id) => {
    //     try {


    //         this.task_id = id;
    //         const response = await apiClient.get(`/task?task_id=${id}`);



    //         if (response) {
    //             console.log(response.data);


    //         }
    //         if (response && response.data.status === 'FAILED') {
    //             console.log('STATUS : Failed')
    //             return;
    //         }
    //         // 2초 후에 다시 실행
    //         setTimeout(() => this.callApiRepeatedly(this.task_id), 2000);
    //     } catch (error) {
    //         console.error('Error:', error);

    //         // 에러 발생 시에도 2초 후 재시도
    //         setTimeout(() => this.callApiRepeatedly(this.task_id), 2000);
    //     }
    // }
    startCalling = (task_id) => {
        this.task_id = task_id;
    
        return new Promise((resolve, reject) => {
            const callApi = async () => {
                try {
                    const response = await apiClient.get(`/task?task_id=${this.task_id}`, {
                        timeout: 2000,
                        headers: { 'Content-Type': 'application/json' }
                    });
                    
                    const { status, result } = response.data;
                    // console.log('Response:', status, result);
    
                    if (status === 'SUCCESS' && result) {
                        // console.log('Task completed:', result);
                        this.stopCalling(); // 인터벌 중지
                        resolve(result); // 결과를 상위 호출자에게 반환
                    } else if (status === 'FAILED') {
                        this.stopCalling();
                        reject(new Error('Task failed'));
                    }
                    // 'PENDING' 또는 기타 상태일 경우 인터벌이 계속 실행됨
                } catch (error) {
                    // console.error('API call failed:', error.message);
                    this.stopCalling();
                    reject(error); // 에러 발생 시 상위 호출자에게 전달
                }
            };
    
            // 즉시 첫 호출
            callApi();
            // 2초 간격으로 반복
            this.intervalId = setInterval(callApi, 2000);
        });
    }
    
    stopCalling = () => {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
            // console.log('API calling stopped');
        }
    }
    // // 사용법
    // const apiCaller = new ApiCaller();
    // apiCaller.startCalling(); // 시작

    // // 필요할 때 중지
    // // apiCaller.stopCalling();

    initSocial() {
        // if (this.Kakao === null) {
        //     this.Kakao = window.Kakao;
        //     this.Kakao.init(process.env.KAKAO_CLIENT_ID);
        // }
    }

    async deleteAccount() {
        try {
            this.Kakao.API.request({
                url: '/v1/user/unlink',
              })
            .then(function(res) {
                alert('success: ' + JSON.stringify(res));
                document.cookie = 'authorize-access-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            })
            .catch(function(err) {
                alert('fail: ' + JSON.stringify(err));
            });
        } catch(error) {

        } finally {}
    }


    async postDeleteImage(id, content, _callback) {
        try {
            const response = await this.deleteImage(id);

            if (response) {

                // this.deleteImage(content);
                if (_callback) _callback()
            }
        } catch(error) {
        }
    }

    replicateWebhook() {
        apiClient.get('/credit/increment');
    }

    shareKakao(url, image) {

        let shareURL = url;
        if (document.body.hasAttribute('data-share-id')) {
            shareURL = `https://yepp.plus-ex.com/image/?id=${document.body.getAttribute('data-share-id')}`
        }

        shareURL += `?utm_source=kakao&utm_medium=content&utm_campaign=open`;

        window.Kakao.Share.sendDefault({
            objectType: 'feed',
            content: {
                title: "AI 선정, 올해의 인물",
                description: "많은 공감을 받은 분들에게 선물을 드려요",
                imageUrl: image,
                link: {
                    mobileWebUrl: shareURL,
                    webUrl: shareURL,
                }
            },
            buttons: [
                {
                    title: "자세히 보기",
                    link: {
                        mobileWebUrl: shareURL,
                        webUrl: shareURL,
                    }
                }
            ]
        });
    }

    shareSystem() {
        if (navigator.share) {
            let shareURL = window.location.href;
            if (document.body.hasAttribute('data-share-id')) {
                shareURL = `https://yepp.plus-ex.com/image/?id=${document.body.getAttribute('data-share-id')}`
            }

            navigator.share({
                title: '[AI 선정, 올해의 인물]\n많은 공감을 받은 분들에게 선물을 드려요\n',
                url: shareURL
            })
        .then(() => console.log('공유 성공'))
        .catch((error) => console.error('공유 실패:', error));
        } else {
        }
    }

    initGAEvents() {
        if (this.isLoggedInStatus) {
            document.querySelector('.button-frame')?.setAttribute('data-gtm-id', 'listLoginTrophy')   
            document.querySelector('.button-grid')?.setAttribute('data-gtm-id', 'listLoginView')   
            document.querySelector('.button-account')?.setAttribute('data-gtm-id', 'listLoginAccount')   
            gsap.utils.toArray('.layer-list .link-text').forEach((link, index) => {
                if (index == 0) {
                    link.setAttribute('data-gtm-id', 'listLoginBrnh')
                } else {
                    link.setAttribute('data-gtm-id', 'listLoginInsta')
                }
            })
        } else {
  
            document.querySelector('.button-frame')?.setAttribute('data-gtm-id', 'listLogoutTrophy')   
            document.querySelector('.button-grid')?.setAttribute('data-gtm-id', 'listLogoutView')   
            document.querySelector('.button-account')?.setAttribute('data-gtm-id', 'listLogoutMy')   
            gsap.utils.toArray('.layer-list .link-text').forEach((link, index) => {
                if (index == 0) {
                    link.setAttribute('data-gtm-id', 'listLogoutBrnh')
                } else {
                    link.setAttribute('data-gtm-id', 'listLogoutInsta')
                }
            })
        }
    }
}