import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default class ScrollAnim {

    constructor(app, initGridView) {
        gsap.registerPlugin(ScrollTrigger);
        this.app = app || null;

        this.container = document.querySelector('.item-container')


        this.isGridView = false;

        if (initGridView) {
            this.appearGridView('appear')
        }

        this.init()
        this.scrollT;
    }

    init() {
        this.initChangeView()
    }

    initElementState() {
        // this.elementsState.push({translateX: this.translateValue, opacity: this.opacityValue, scale: this.scaleValue})
    }

    appearGridView(state) {
        if (state === 'appear') {
            this.isGridView = true;
            this.container.classList.add('grid-view')
            gsap.to('.item-image', { xPercent: 0, '--ox': 1, duration: 0.2 })
            if (this.app && this.app.observer) {

                this.app.observer.refresh()
                // console.log(this.app)
            }
        } else {
            this.isGridView = false;
            this.container.classList.remove('grid-view')
            gsap.delayedCall(0.1, () => {
                // this.updateElements()
            })
        }

        ScrollTrigger.refresh()
    }

    initChangeView() {
        if (document.querySelector('.button-grid')) {
            document.querySelector('.button-grid').addEventListener('click', () => {
                if (!this.container.classList.contains('grid-view')) {
                    this.appearGridView('appear')
                } else {

                    this.appearGridView()
                }
            })
        }


        const buttonFrame = document.querySelector('.button-frame') || null;
        buttonFrame?.addEventListener('click', () => {
            if (this.app) {

                if (this.app.ordering == null) {
                    buttonFrame.classList.add('is-ordering')    
                    this.app.ordering = '-reaction';
                } else {
                    this.app.ordering = null;
                    buttonFrame.classList.remove('is-ordering')
                }
                if (this.app.observer) {
                    this.app.observer.refresh()
                }


                this.app.next = null;

                this.app._count = 0;

                const _callback = this.app.getDATA.bind(this.app);
                this.app.changeFilter(_callback)
            

            }

        })
    }
    updateElements() {

        var scrolly = this.scrollT;
        
        // console.log(scrolly)
        if(scrolly == undefined) return;

        const windowCenter = window.innerHeight / 2;

        const height = document.querySelector('.item-size').getBoundingClientRect().height;

        const topValue = document.querySelector('.top-height').clientHeight;
        const images = gsap.utils.toArray('.item-image', this.container);


        if (images.length <= 0) return;

        var length = images.length;
        var con = (scrolly - topValue + windowCenter) / height;
        var now = Math.floor(con);
        var nex1 = now + 1;
        var nex2 = now + 2;
        var pre1 = now - 1;
        var pre2 = now - 2;

        var scaleRatio = 0.2;
        var opacityRatio = 0.8
        var moveRatio = 25 * -1;

        var item = images[now];
        var scaleVal = 1 - Math.abs((con - (now + 0.5))) * scaleRatio;
        var opacityVal = 1 - Math.abs((con - (now + 0.5))) * opacityRatio;
        var moveVal = (con - (now + 0.5)) * moveRatio
        // console.log('333 : ',moveVal)
        if (item) {
            item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;
            item.style.opacity = opacityVal;
        }
        // item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;

        if(nex1 < length){
            var item = images[nex1]
            var scaleVal = 1 - Math.abs((con - (nex1 + 0.5))) * scaleRatio;
            var opacityVal = 1 - Math.abs((con - (nex1 + 0.5))) * opacityRatio;
            var moveVal = (con - (nex1 + 0.5)) * moveRatio;
            if (item) {

                item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;
                item.style.opacity = opacityVal;
            }
        }

        if(nex2 < length){
            var item = images[nex2]
            var scaleVal = 1 - Math.abs((con - (nex2 + 0.5))) * scaleRatio;
            var opacityVal = 1 - Math.abs((con - (nex2 + 0.5))) * opacityRatio;
            var moveVal = (con - (nex2 + 0.5)) * moveRatio
            if (item) {

                item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;
                item.style.opacity = opacityVal;
            }
        }
        if(pre1 >= 0){
            var item = images[pre1]
            var scaleVal = 1 - Math.abs((con - (pre1 + 0.5))) * scaleRatio;
            var opacityVal = 1 - Math.abs((con - (pre1 + 0.5))) * opacityRatio;
            var moveVal = (con - (pre1 + 0.5)) * moveRatio
            if (item) {

                item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;
                item.style.opacity = opacityVal;
            }
        }
        if(pre2 >= 0){
            var item = images[pre2];
            var scaleVal = 1 - Math.abs((con - (pre2 + 0.5))) * scaleRatio;
            var opacityVal = 1 - Math.abs((con - (pre2 + 0.5))) * opacityRatio;
            var moveVal = (con - (pre2 + 0.5)) * moveRatio
            if (item) {

                item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;
                item.style.opacity = opacityVal;
            }
        }
        
    }

    _updateElements() {
        if (!this.scrollT) return;
    
        const windowCenter = window.innerHeight / 2;
        const height = document.querySelector('.item-size')?.getBoundingClientRect().height || 0;
        const topValue = document.querySelector('.top-height')?.clientHeight || 0;
        const images = gsap.utils.toArray('.item-image', this.container);
    
        if (images.length === 0) return;
    
        const con = (this.scrollT - topValue + windowCenter) / height;
        const now = Math.round(con);
    
        const scaleRatio = 0.2;
        const opacityRatio = 0.8; // 또는 동적으로: Math.min(1, window.innerHeight / (height * 2));
        const moveRatio = -25;
    
        const range = 2;
        for (let i = Math.max(0, now - range); i <= Math.min(images.length - 1, now + range); i++) {
            const item = images[i];
            const distance = con - (i + 0.5);
            const absDistance = Math.abs(distance);
    
            // opacity가 1에 가까워지도록 조정
            const opacityVal = Math.max(0.2, 1 - absDistance * opacityRatio); // 최소 0.2 보장
            const scaleVal = Math.max(0.8, 1 - absDistance * scaleRatio); // scale도 최소값 설정
            const moveVal = distance * moveRatio;
    
            if (item) {
                item.style.transform = `scale(${scaleVal}) translateX(${moveVal}%)`;
                item.style.opacity = opacityVal;
            }
        }
    }
    
    scrollTransition() {
        this.scrollT = 0;
        this.updateElements();
        // document.body.classList.remove('is-lock')

        if (!this.ticker) {
            
            if (window.app && !window.app.fps) {
                gsap.ticker.fps(60)
            }
            this.ticker = gsap.ticker.add(() => {
                this.scrollT += (window.scrollY - this.scrollT) * 0.9;
                this.updateElements();
            })
        }
    }
}