import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import gsap from 'gsap';
import CropImage from './CropImage';
// swiper
import "swiper/css";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { domToJpeg } from 'modern-screenshot';

import GenerateUI from './GenerateUI'
import p5 from "p5";
import sketch from './sketch';
import { floatingServiceHoldButton, creteErrorState, floatingCreditExhausted } from './utils'

export default class PG_Generate {
    constructor(app) {
        // console.log('Init : PG_Generate')
        this.app = app;

        this.resultImage = 'https://replicate.delivery/xezq/DxEQw5pCVIJCA9XJo08DG1sakWDeVeebQB5jJ8QL4Qc6RYynA/out-0.jpg'

        this.frames = null;

        // const titles = [
        //     '1_The-Person-of-the-Year',
        //     '2_Creative-Partner',
        //     '3_Gentle',
        //     '4_Ace-Card',
        //     '5_Holiday',
        //     '6_Christmas',
        //     '7_GOAT',
        //     '8_Yellow-Jacket',
        //     '9_King-Mask',
        //     '10_Wanted',
        //     '11_Capture-the-moment',

        // ]

        // for (let i = 0; i < titles.length; i++) {
        //     this.frames.push({
        //         name: titles[i].split('_')[1].split('-').join(' '),
        //         card_frame: `/assets/frame/${titles[i]}.png`
        //     })
        // }

        // this.getFrames()

        this.backgroundColor = 'rgb(0,0,0)';
        this.layers = gsap.utils.toArray('.layer-generate');

        this.buttonGenerate = gsap.utils.toArray('.button-generate')
        this.buttonTakePhoto = document.querySelector('.button-continue')
        this.buttonContinue = document.querySelector('.button-continue-progress')
        this.buttonPost = gsap.utils.toArray('.button-post')
        this.buttonSave = document.querySelector('.button-save');


        this.buttonFlow = document.querySelector('.button-replicate-flow');
        this.cropper = null;

        this.current = 0;

        this.imageQuality = 0.8;

        this.UI = new GenerateUI(this);

        this.CURRENT_REQUEST = null;
        this.temp = []

        this.init()


        this.buttonPost.forEach(button => button.disabled = true)
    }

    async init() {

        const isLoggedIn = await this.app.isLoggedIn();
        if (isLoggedIn) {
            // 로그인된 유저만 서비스 상태 체크
            const isActive = await this.app.getServiceStatus();
            if (isActive === false) {
                const content = floatingServiceHoldButton()
                document.querySelector('.floating.column-container').querySelector('.button-text')?.remove()
                document.querySelector('.floating.column-container').appendChild(content);
          
            } else {
                const isCreditExhausted = await this.app.getCredit();

                if (isCreditExhausted.is_credit_exhausted) {
                    const content = floatingCreditExhausted()
                    document.querySelector('.floating.column-container').querySelector('.button-text')?.remove()
                    document.querySelector('.floating.column-container').appendChild(content)
       
                }
            }

        }

        Swiper.use([Pagination]);
        this.initInputImage()
        this.initGenerate()

        this.initPost()
        this.initSave()

        this.initCardFrames()
        this.initContinue()
        // this.generateSelectFrame()
        // this.gameToResult()
        this.UI.progress_Onboard('appear');
        // this.progress_Game('init')
        // gsap.delayedCall(0.2, () => {
            // 
            // this.UI.progress_Loading('appear')
    // 
            // gsap.delayedCall(2, () => {
                // this.UI.progress_Loading('disappear')
            // })
        // })

        // this.initGame()
        // this.progress_Game('init')
        // console.log(this.Game)
    }

    async initCardFrames() {
        const response = await this.app.getCardFrames();

        if (response) {
            
            this.frames = response;
        }
    }
    initContinue() {
        
        this.buttonTakePhoto.addEventListener('click', () => {

            this.buttonTakePhoto.disabled = true;
            // console.log('click continue')
            // this._finishGame()
            const _callback = () => {
                this.UI.progress_TakeImage('appear')
                this.Game.stopLoop()

                this.app.replicateWebhook();
            }

            
            this.UI.progress_Game('disappear', _callback)
        })

        this.buttonContinue.addEventListener('click', () => {
            this.buttonContinue.disabled = true;
            const _callback = () => {
                this.buttonContinue.disabled = false;
                this.Game.remove()
                const callback = () => this.generateSelectFrame();
                this.UI.progress_SelectionFrame('appear', callback)
            }

            this.UI.progress_TakeImage('disappear', _callback)
        })
    }

    deleteCropImage() {
        // this.cropImage.remove()
        // this.cropImage = null;
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
        return new File([u8arr], filename, {type: mime});
    }

    progress_Game(state, retry) {
        
        if (state == 'init') {
            const _callback = () => {
                this.UI.progress_Game('appear')
            }

            document.querySelector('.button-game-start').disabled = false;
            this.UI.progress_Crop('disappear', _callback)

            gsap.delayedCall(0.2, () => {
                this.Game = new p5(sketch);
                // console.log('go')
            })
            this.buttonTakePhoto.disabled = false;
        } else if (state == 'reinit') {
            const _callback = () => {
                this.UI.progress_Game('appear', null, true)
            }
            this.Game.clearGame()
            this.Game.reinit()
            this.UI.progress_TakeImage('disappear', _callback)
            this.buttonTakePhoto.disabled = false;
            
        } else if (state == 'continue') {
            this.Game.playLoop()
        }

    }

    finishGame() {

        this.UI.progress_TakeImage('appear')
        // this.UI.progress_Game('disappear', _callback)
    }
    gameToResult(from) {
        if (!this.resultImage) return;

        // console.log('game To Result ', from)


        if (document.querySelector('.layer-generate[data-type="Take"] .item-image.crop .thumb img')) {
            document.querySelector('.layer-generate[data-type="Take"] .item-image.crop .thumb img').remove()
        }
        const image = document.createElement('img');
        image.src = this.resultImage;
        document.querySelector('.layer-generate[data-type="Take"] .item-image.crop .thumb').appendChild(image);

        image.onload = () => {
            gsap.to(image, { opacity: 1, duration: 0.4, ease: 'power1.inOut' })
        }

        // 시작 전
        if (from === 'before_start') {

            // this._finishGame()
            const _callback = () => {
                this.UI.progress_TakeImage('appear')
                this.Game.stopLoop()
            }
            this.UI.progress_Game('disappear', _callback)
        // 게임 진행 중
        } else if (from == 'ing') {
            this.UI.progress_Notification_Complete_Replicate(this.resultImage)
        // 게임 종료
        } else if (from === 'finish') {
            // this._finishGame()
            const _callback = () => {
                this.UI.progress_TakeImage('appear')
                this.Game.stopLoop()
            }
            this.UI.progress_Game('disappear', _callback)
        }
    }

    _finishGame() {
        const _callback = () => {
            this.UI.progress_TakeImage('appear')
            this.Game.stopLoop()
        }
        this.UI.progress_Game('disappear', _callback)
    }

    testError() {
        this.UI.progress_Error('appear', this.CURRENT_REQUEST);
        // console.log(' testError : ',this.Game)
        if (this.UI.progress == 'ing') {
            // this.UI.progress = 'GameStopped'
            // this.Game.stopLoop()
        }
    }

    async initGenerate() {
        const that = this;

        this.buttonGenerate.forEach(button => {
            if (!button.classList.contains('button-move-generate')) {

                button.addEventListener('click', () => {
                    that.replicate_0(button)
                });
            }
        });

        this.buttonFlow.addEventListener('click', () => {
            if (that.CURRENT_REQUEST !== null) {
                this.UI.progress_Error('disappear')


                gsap.delayedCall(0.2, () => {
                    if (that.CURRENT_REQUEST === 'replicate_0') {
                        that[this.CURRENT_REQUEST](this.buttonFlow)
                    } else {
                        let _temp = that.temp[this.CURRENT_REQUEST.split('_')[1]];
        
                        if (_temp) {
                            that[this.CURRENT_REQUEST](_temp);
        
                        }
                    }
                })

            }
        })
    }
    
    async replicate_0(button) {
        if (!this.cropper) {
          console.warn('Cropper가 초기화되지 않았습니다.');
          return;
        }
      
        const { error, progress } = this.UI;
        this.buttonGenerate.forEach(button => button.disabled = true);
        if (this.UI.error) {
          this.UI.progress_Error('disappear');
        }
        const croppedDataUrl = this.cropper.cropper
          .getCroppedCanvas({ minWidth: 900, minHeight: 1200, quality: this.imageQuality })
          .toDataURL('image/jpeg', this.imageQuality);
      
        this.init_image = croppedDataUrl;
      
        if (!button.dataset.index) {
          this.UI.progress_Error('disappear');
        }
      
        const result = await this.app.replicateImage_First(this.init_image);
        this.temp[0] = this.init_image;
        this.CURRENT_REQUEST = 'replicate_0';
      


        // 크레딧 소진 여부 확인
        if (result.status) {
            if (result.status === 403) {
                this.UI.fetchErrorState('is-credit-exhausted')
            } else {
                this.UI.fetchErrorState('service-hold')
            }
 
            return;
        }

        if (button.dataset.index) {
            if (button.dataset.index === 'generate-init') {
                this.progress_Game('init');
              } else if (button.dataset.index === 'error') {
                this.UI.progress_Error('disappear');
              } else if (button.dataset.index === 'regenerate-in-take') {
                gsap.set('.timer', { clearProps: 'all' });
                gsap.set('.floating-continue', { clearProps: 'all' });
                this.progress_Game('reinit');
              }
        }
        // console.log('error', result)
        if (result.task_id) {
        //   console.log('First response:', result);
          const taskResult = await this.app.startCalling(result.task_id);
        //   console.log('Task result:', taskResult.s3_url, taskResult.image_url);
          this.replicate_1({ s3_url: taskResult.s3_url, mask_image_url: taskResult.image_url });
        } else if(result.status === 423) {
            this.replicateWithError()
        } else {
            // console.log('error', error)
            this.CURRENT_REQUEST = 'replicate_0';
            this.testError();
            this.buttonGenerate.forEach(button => button.disabled = false);
        }
    }

    replicateWithError(index) {

        const layer = document.querySelector('.layer-game')
        const content = floatingServiceHoldButton()
        const floating = layer.querySelector('.floating');
        layer.querySelector('.floating').querySelector('.button-text')?.remove()
        layer.querySelector('.floating').appendChild(content);

        gsap.set(floating, { 
            display: 'flex'
        })
        gsap.to(floating, { 
            autoAlpha: 1,
            duration: 0.3,
            ease: 'power1.inOut'
        })
    }

    async replicate_1(data) {
        if (!data) {
            // console.warn('Cropper가 초기화되지 않았습니다.');
            return;
        }
    
        try {
            const { error, progress } = this.UI;
            this.temp[1] = data;
            if (this.UI.error) {

                this.UI.progress_Error('disappear')

                // this.Game.playLoop()
            }

    
            const response = await this.app.replicateImage_Second(data);

            this.CURRENT_REQUEST = 'replicate_1';

            // console.log('error', response)
            if (response.task_id) {
                // console.log('First response:', response);
                const taskResult = await this.app.startCalling(response.task_id); // 결과 기다림
                // console.log('Task result:', taskResult);
                this.replicate_2({background_image_url: taskResult.image_url}); // 다음 단계로 진행
            } else if(response.status === 423) {
                this.replicateWithError()
            } else {
                this.CURRENT_REQUEST = 'replicate_1';
                this.testError();
                this.buttonGenerate.forEach(button => button.disabled = false)
            }


    
        } catch (error) {

            // console.log('error', error)
            this.CURRENT_REQUEST = 'replicate_1';
            this.testError();
            this.buttonGenerate.forEach(button => button.disabled = false)
        } finally {
        }
    }

    async replicate_2(data) {
        if (!data) {
            // console.warn('Cropper가 초기화되지 않았습니다.');
            return;
        }
    
        try {

            this.temp[2] = data;
            const { error, progress } = this.UI;

            this.buttonGenerate.forEach(button => button.disabled = true)
            if (this.UI.error) {

                this.UI.progress_Error('disappear')

                // this.Game.playLoop()
            }
    
            const response = await this.app.replicateImage_Third(data);

            this.CURRENT_REQUEST = 'replicate_2';

         
            
            // console.log(response.status)
            if (response.task_id) {
                // console.log('First response:', response);
                const taskResult = await this.app.startCalling(response.task_id); // 결과 기다림
                // console.log('Task result:', taskResult);

                if (taskResult) {
                    // console.log(taskResult)
                    this.resultImage = taskResult.image_url;
                    this.gameToResult(this.UI.progress)
                    this.CURRENT_REQUEST = null;
    
                }

            } else if(response.status === 423) {
                this.replicateWithError()
            } else {

                this.CURRENT_REQUEST = 'replicate_2';
                this.testError();
                this.buttonGenerate.forEach(button => button.disabled = false)
            }
        } catch (error) {
            // console.error('이미지 복제 중 오류 발생:', error);
            this.CURRENT_REQUEST = 'replicate_2';
            this.testError();
            this.buttonGenerate.forEach(button => button.disabled = false)
        } finally {
            this.buttonGenerate.forEach(button => button.disabled = false)
        }
    }

    async handlePostClick(button) {
        try {

            // console.log('POST API')
            this.buttonPost.forEach(button => button.disabled = true)
            this.UI.progress_Loading('appear')
            const newImage = document.createElement('div');
            newImage.classList.add('item-image')
            newImage.classList.add('item-result')
            newImage.classList.add('hidden')
            const _clone = document.querySelector('.swiper-slide-active .thumb').cloneNode(true);
        
            gsap.utils.toArray('img', _clone).forEach(c => {
                c.crossOrigin = 'anonymous';
            })
            newImage.appendChild(_clone);

            document.body.appendChild(newImage)
            
            const dataUrl = await domToJpeg(newImage, { quality: 0.8 }); // 적절한 품질 값 설정
            const base64Only = dataUrl.split(',')[1];
            // console.log(dataUrl)
            // // // DataURL을 File로 변환
            // const imageFile = imageToBase64(dataUrl, 'new_image.jpg');

            this.generative_image = dataUrl;
            this.selected_cardFrame = document.querySelector('.swiper-slide-active').dataset.id;

            // API 호출
            const response = await this.app.generateImage(this.init_image, this.generative_image, this.selected_cardFrame);

            this.CURRENT_REQUEST = 'generate';


            if (response && response.data.generative_image) {

                this.UI.progress_Loading('disappear')

                this.userOutput = response.data.generative_image;

                document.body.setAttribute('data-share-id', response.data.id)
                this.appearResult();
                this.CURRENT_REQUEST = null;

            } else {
                this.CURRENT_REQUEST = 'generate';
                this.UI.progress_Loading('disappear')
                this.testError('generate')
            }
        } catch (error) {

            this.CURRENT_REQUEST = 'generate';
            this.testError('generate')
            this.UI.progress_Loading('disappear')
            this.buttonPost.forEach(button => button.disabled = false)

        } finally {
            this.CURRENT_REQUEST = null
        }
    }

    async initPost() {
        this.buttonPost.forEach(button => {
            button.addEventListener('click', this.handlePostClick.bind(this, button));
        });
    }

    appearResult() {
        const layer = document.querySelector('.layer[data-type="Share"]');
        const image = document.createElement('img');
        image.src = this.userOutput;
        layer.querySelector('.item-image .thumb').appendChild(image);

        image.onload = () => {
            gsap.to(image, { opacity: 1, duration: 0.4, ease: 'power1.inOut' })
        }
        const _callback = () => {
            this.UI.progress_Share('appear')
        }
        this.UI.progress_SelectionFrame('disappear', _callback)

        document.querySelector('.button-icon[data-share="kakao"]').addEventListener('click', () => {
            this.app.shareKakao(window.location.href, layer.querySelector('.item-image .thumb img').src)
        })
        document.querySelector('.button-icon[data-share="system"]').addEventListener('click', () => {
            this.app.shareSystem()
        })
    }
    
    async initSave() {

    }

    async blobToData(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    async inputImage(file) {
        if (!file) return;
        try {

            const maxSize = 6 * 1024 * 1024;

            if (file.size > maxSize) {
                alert('6MB 이하의 파일을 선택해 주세요')
                return;
            }

            const resData = await this.blobToData(file);

            document.body.classList.add('has-file')

            let _isToResize = true;

            if (this.cropper) {
                this.cropper.destroy();
                _isToResize = false;
            }

            const newCropper = new CropImage(resData);
            this.cropper = newCropper;

            const _callback = () => {
                this.UI.progress_Crop('appear')
                this.buttonGenerate.forEach(button => button.disabled = false)
                if (_isToResize) {
                    this.cropper.updateSize()
                }
            }
            if (this.app.show_user_guide) {
                this.UI.progress_Onboard('disappear', _callback)
            } else {
                // _callback()
                this.UI.progress_Onboard('disappear', _callback)
            }
        } catch(error) {
            // 에러 발생 - alert
        }
    }


    async initInputGuide() {
        try {
            const response = await this.app.toggleGuide(!document.querySelector('#input-hidden-guide').checked);
            if (response) {
            }
        } catch(error) {
        }
    }

    initInputImage() {

        gsap.utils.toArray('.input-file-container').forEach(input => {
            const fileInput = input.querySelector('input');
            fileInput.addEventListener('change', (event) => {
                if (event.target.files.length > 0) {
                    this.inputImage(event.target.files[0])

                    // this.initInputGuide()
                } else {

                }
            });
        })
    
    }

    generateSelectFrame() {
        const layer = document.querySelector('.layer[data-type="SelectionFrame"]');
        const swiperContainer = document.createElement('div');
        swiperContainer.classList.add('swiper')

        swiperContainer.innerHTML = `
            <div class="swiper-wrapper">
                ${this.frames.map((frame, i) => `
                    <div class="swiper-slide item-image item-result" data-id=${frame.id}>
                        <div class="thumb">
                            <div class="thumb-frame">
                                <img src="/assets/frame/${frame.name.split(' ').join('_')}.png" alt="frame-${frame.name}" />
                            </div>
                            <div class="thumb-result">
                                <img src="${this.resultImage}" alt="Slide Image" />
                            </div>
                        </div>
                    </div>`
                )
                .join('')}
            </div>
        `;

        const label = layer.querySelector('.info-label');
        const index = layer.querySelector('.info-indicator span');
        layer.querySelector('.swiper-container').appendChild(swiperContainer)
        label.innerHTML = this.frames[0].name;

        let swiper;
        const initSwiper = () => {
            swiper = new Swiper(swiperContainer, {
                // Default parameters
                slidesPerView: 'auto',
                spaceBetween: 18,
                centeredSlides: true,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                on: {
                    init: () => {
                        this.UI.appearSwiper();
                        this.buttonPost.forEach(button => button.disabled = false)
                    },
                },
            });
            swiper.on('slideChange', (e) => {
                const { activeIndex, previousIndex } = e;


                label.innerHTML = this.frames[activeIndex].name;
                index.innerHTML = activeIndex + 1;
            })
        };

        const destroySwiper = () => {
            if (swiper) {
                swiper.destroy(true, true);
                swiper = undefined;
                gsap.utils.toArray(".swiper-slide", _swiper).forEach((s) => {
                    gsap.set(s, { clearProps: "all" });
                });
            }
        };
        // const handleResize = () => {
        //     if (window.innerWidth <= 768) {
        //         destroySwiper();
        //     } else {
        //         if (!swiper) {
        //             initSwiper();
        //         }
        //     }
        // };

        // window.addEventListener("resize", handleResize);
        initSwiper();

    }
}