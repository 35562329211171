import gsap from 'gsap';
import TextTranslate from './TextTranslate';
import lottie from 'lottie-web';
import animationData from './data/loading.json';

export default class PG_Signup {
    constructor(app) {
        this.app = app;
        this.form = document.querySelector('#form-signup');
        this.username = this.form.querySelector('#nickname');
        this.emailId = this.form.querySelector('#email-id');
        this.emailDomain = this.form.querySelector('#email-domain');
        this.emailDomainSelect = this.form.querySelector('.input-email-domain select');
        this.password = this.form.querySelector('#password');
        this.passwordReenter = this.form.querySelector('#password-reenter');
        this.verificationCode = this.form.querySelector('.input-email-code input');
        this.buttonSubmit = this.form.querySelector('#button-signup');
        this.buttonNext = this.form.querySelector('#button-next');
        this.buttonVerifyCode = this.form.querySelector('#button-send-verify');
        this.emailCodeContainer = this.form.querySelector('.input-email-code');
        this.nickname_MAXLENGTH = 20;

        this.currentStep = 0;
        this.identify_id = null;
        this.isVerified = false; // 인증 완료 여부 플래그 추가

        this.checkSocial()

        this.checkLoginStatus()

        this.initRandomNickname()

    }

    clearCode() {

        this.progress_signup('appear');
        const cleanUrl = window.location.pathname;
        window.history.replaceState({}, document.title, cleanUrl);
    }

    checkSocial() {
        const _auth = this;

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
    
        if (code) {
            console.log('인가 코드:', code);
            getAccessToken(code);
        } else {
            console.error('인가 코드가 없습니다.');
            _auth.clearCode();
        }


    
        // 1. code로 액세스 토큰 발급
        function getAccessToken(code) {
          fetch('https://kauth.kakao.com/oauth/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                grant_type: 'authorization_code',
                client_id: '4ad8ee8789798c26b51bea5a99458452',
                redirect_uri: 'https://staging.yepp.plus-ex.com/signup',
                code: code
            })
          })
            .then(response => response.json())
            .then(data => {
                const accessToken = data.access_token;
                if (accessToken) {
                    console.log('액세스 토큰:', accessToken);
                    getUserInfo(accessToken); // 2. 유저 정보 요청
                } else {
                    _auth.clearCode();
                    console.error('액세스 토큰 발급 실패:', data);
                }
            })
            .catch(error => _auth.clearCode());
        }
    
        // 2. 액세스 토큰으로 유저 정보 가져오기
        function getUserInfo(accessToken) {
          fetch('https://kapi.kakao.com/v2/user/me', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
            .then(response => response.json())
            .then(data => {
                const userId = data.id;
                const email = data.kakao_account?.email || '이메일 제공 동의 안함';
     

                _auth.app.userDATA = {
                    email: email,
                    social_id: userId,
                    social_type: 1
                }
                _auth.isSocial = true;
                _auth.nextStep(true);

            })
            .catch(error => _auth.clearCode());
        }

        this.init()
    }

    initRandomNickname() {
        const list1 = [
           "금빛","은빛","푸른빛","붉은빛","초록빛","보랏빛","분홍빛","핑크빛","검은빛","황금빛","흰빛","달빛","별빛","청록빛","이슬빛","오로라빛","형광빛","야광빛","네온빛","무지갯빛",
        ];

        const list2 = [
            "삼겹살","크룽지","갑오징어","치즈떡","핫바","곰팡이","붕어빵","감자튀김","훈제오리","마늘빵","해파리","구미베어","카피바라","고기만두","우유식빵","고라니","요술봉","비둘기","솜사탕","고구마"
        ];


        function generateNickname() {
            const random1 = list1[Math.floor(Math.random() * list1.length)];
            const random2 = list2[Math.floor(Math.random() * list2.length)];
            const nickname = random1 + random2;
            return nickname;
        }

        document.querySelector('.button-random').addEventListener('click', () => {
            const v = generateNickname();
            this.username.value = v;

            this.validateForm()
        })
    }
    async checkLoginStatus() {
        const isLoggedIn = await this.app.isLoggedIn();
        if (isLoggedIn) {
            window.location.href = '/'; // 로그인된 경우 index로 리다이렉트
        }
    }
    init() {
        this.initSignup = this.initSignup.bind(this);
        this.validateForm = this.validateForm.bind(this);

        this.initEvents();
        this.initCheckBox();
        this.initEmailDomain();

        this.verificationCode.disabled = true;

        // gsap.delayedCall(0.1, () => {
        //     this.progress_signup('appear');
        //     // this.updateStepUI();
        // });
    }

    _initSignup() {
        if (this.isSocial) {
            this.initSocialSignup()
        } else {
            this.initSignup();
        }
    }

    initEvents() {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Enter' && this.validateForm()) {
                if (this.currentStep === 0) {
                    this.nextStep();
                } else {
                    if (this.isSocial) {
                        this.initSocialSignup()
                    } else {
                        this.initSignup();
                    }
                }
                
                event.preventDefault();
            }
        });
        this.emailId.addEventListener('input', this.restrictEmailInput.bind(this));
        this.buttonNext.addEventListener('click', () => this.nextStep());
        this.buttonSubmit.addEventListener('click', () => this._initSignup());
        this.buttonVerifyCode.addEventListener('click', () => {

            this.buttonVerifyCode.disabled = true;
            if (this.buttonVerifyCode.dataset.state === 'sended') {
                this.verifyEmailCode();
            } else {
                this.onRequestCode();
                alert('요청하신 이메일로 인증번호가 전송되었습니다');
                this.buttonVerifyCode.setAttribute('data-gtm-id', 'signupVerifyCode')
               
                this.changeVerifyButtonState('sended');
                this.verificationCode.disabled = false; // 인증번호 입력 필드 활성화
                this.verificationCode.focus();
                const errorText = document.querySelector('.error-verify .error-text');
                this.disappearError(errorText)
            }
        });

        gsap.utils.toArray('.button-layer').forEach(button => {
            button.addEventListener('click', () => {
                this.appearLayer(document.querySelector(`.layer-privacy[data-terms="${button.dataset.terms}"]`));
            });
        });

        gsap.utils.toArray('.layer-privacy').forEach(layer => {
            layer.querySelector('.button-close-layer').addEventListener('click', () => {
                this.disappearLayer(layer);
            });
        });
    }

    initEmailDomain() {
        const select = this.emailDomainSelect;
        const customOption = document.createElement('option');
        customOption.value = 'custom';
        customOption.text = '직접 입력';
        this.emailDomain.value = select.querySelector('option').value;
        select.appendChild(customOption);
    
        // 이메일 형식 검증 함수
        const isValidEmail = (email) => {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // aa@aa.cc 형식 체크
            return emailPattern.test(email);
        };
    
        // 셀렉트 변경 이벤트
        select.addEventListener('change', () => {
            if (select.value === 'custom') {
                this.emailDomain.hidden = false;
                this.emailDomain.value = '';
                this.emailDomain.disabled = false;
                this.emailDomain.classList.add('is-select');
                this.emailDomain.focus();
            } else {
                this.emailDomain.hidden = true;
                this.emailDomain.disabled = true;
                this.emailDomain.classList.remove('is-select');
                this.emailDomain.value = select.value;
            }
            // 이메일 전체 검증 (username + @ + domain)
            const fullEmail = `${this.username.value.trim()}@${this.emailDomain.value.trim()}`;
            if (!isValidEmail(fullEmail)) {
                // console.log('유효한 이메일 형식이 아닙니다 (예: aa@aa.cc)');
            }
            this.validateForm();
        });
    
        // 직접 입력 시 실시간 검증
        this.emailDomain.addEventListener('input', () => {
            const fullEmail = `${this.username.value.trim()}@${this.emailDomain.value.trim()}`;
            if (!isValidEmail(fullEmail)) {
                // console.log('유효한 이메일 형식이 아닙니다 (예: aa@aa.cc)');
            }
            this.validateForm();
        });
    }
    restrictEmailInput(event) {
        const currentValue = this.emailId.value;
        const validValue = currentValue.replace(/@/g, ''); // @만 제거
        this.emailId.value = validValue;
    
        // 최대 길이 제한 (max="20")
        if (this.emailId.value.length > 20) {
            this.emailId.value = this.emailId.value.substring(0, 20);
        }
    }

    initLoading() {
        const layer = document.querySelector('.layer-loading');
        this.loadingAnim = lottie.loadAnimation({
            container: layer.querySelector('.loading'), // <div> 요소를 컨테이너로 설정
            renderer: 'svg', // SVG 렌더러를 사용하여 품질을 높임
            loop: true,
            autoplay: false,
            animationData: animationData,
        });
    }
    progress_Loading(state, _callback) {
        const layer = document.querySelector('.layer-loading');
        const loading = layer.querySelector('.loading');

        if (state === 'appear') {

            gsap.set(layer, { zIndex: 9999 })
            if (this.loadingAnim.isPaused) {
                this.loadingAnim.play()
            }

            gsap.to(loading, { opacity: 1, duration: 0.6, ease: 'power1.inOut', delay: 0.4 })

            const callback = () => {
                if (_callback) _callback()
            }
    
            this.apparTransition(layer, null, null, callback)
        } else {
              
            const callback = () => {
                gsap.set([layer], { clearProps: 'all' })

                if (_callback) _callback()
            }
            this.disapparTransition(layer, callback)
        }
    }
    async onRequestCode() {
        const email = `${this.emailId.value}@${this.emailDomain.value}`;
        const success = await this.app.requestEmailVerification(email);

        
        if (success) {
            this.identify_id = success.identify_id;
        } else {
            alert('이메일 전송에 문제가 생겼어요. 다시 시도해주세요');
            this.changeVerifyButtonState('request');
        }
    }

    async verifyEmailCode() {
        const verify_code = document.getElementById('verify-code').value;
        const errorText = document.querySelector('.error-verify .error-text');
        const identify_id = this.identify_id;
        const response = await this.app.verifyEmailCode(identify_id, Number(verify_code));

        // console.log('verifyEmailCode ', { response });
        if (response) {
            if (response.status === 'success') {

                this.buttonVerifyCode.disabled = true;

                alert('이메일 인증이 완료되었습니다')
                this.isVerified = true; // 인증 완료 플래그 설정
                this.changeVerifyButtonState('confirmed');
                this.verificationCode.disabled = true; // 인증 완료 후 비활성화
                this.validateForm(); // 비밀번호 상태 재검사
                this.disappearError(errorText)
            } else {
                // console.log('error ', response);
                errorText.innerHTML = response.error;
                if (response.error.includes('시도 횟수')) {

                    this.changeVerifyButtonState('request')
                } 
                this.buttonVerifyCode.disabled = false;
                this.appearError(errorText);
            }
        }
    }

    appearError(errorText) {
        gsap.to(errorText, {
            autoAlpha: 1,
            duration: 0.2,
            ease: 'power1.out'
        });
    }

    disappearError(errorText) {
        gsap.to(errorText, {
            autoAlpha: 0,
            duration: 0.2,
            ease: 'power1.out',
        });
    }

    changeVerifyButtonState(state) {

        // console.log('changeVerifyButtonState' , state)
        if (state === 'sended') {
            document.getElementById('verify-code').disabled = false;
            document.getElementById('verify-code').value = '';
            this.buttonVerifyCode.setAttribute('data-gtm-id', 'signupVerifyCode')
            this.buttonVerifyCode.disabled = false;
            this.buttonVerifyCode.dataset.state = 'sended';
            this.buttonVerifyCode.querySelector('.label').innerHTML = '인증 확인';
        } else if (state === 'confirmed') {
            document.querySelector('.signup-layer[data-index="0"]').classList.add('is-verified');
            this.buttonVerifyCode.disabled = true;
            this.buttonVerifyCode.querySelector('.label').innerHTML = '인증 완료';
        } else if (state === 'request') {
            this.buttonVerifyCode.disabled = false;
            this.buttonVerifyCode.dataset.state = '';
            this.buttonVerifyCode.setAttribute('data-gtm-id', 'signupSendCode')
            this.buttonVerifyCode.querySelector('.label').innerHTML = '인증 요청';
        }
    }

    async initSignup() {
        if (!this.validateForm()) return;

        this.buttonSubmit.disabled = true;

        const email = `${this.emailId.value}@${this.emailDomain.value}`;
        const password = this.password.value;
        const name = this.username.value;

        const errorText = document.querySelector('.error-nickname .error-text');
        const result = await this.app.handleSignup(this.identify_id, password, name);
        if (result.success) {
            localStorage.setItem('signupCompleted', 'true');
            const callback = () => this.progress_onboarding('appear');
            this.progress_signup('disappear', callback);
        } else {
            this.buttonSubmit.disabled = false;
            errorText.innerHTML = result.message;
            this.appearError(errorText)
        }
    }

    async initSocialSignup() {
        this.buttonSubmit.disabled = true;

        const name = this.username.value;

        this.app.userDATA.name = name;

        const errorText = document.querySelector('.error-nickname .error-text');
        const result = await this.app.signup();
        if (result.success) {

            console.log('result')
            localStorage.setItem('signupCompleted', 'true');
            const callback = () => this.progress_onboarding('appear');
            this.progress_signup('disappear', callback);
        } else {
            this.buttonSubmit.disabled = false;
            errorText.innerHTML = result.message;
            this.appearError(errorText)
        }
    }


    nextStep(passValidate) {
        if (passValidate) {
            this.buttonNext.disabled = true;

            this.currentStep++;
            this.updateStepUI();

            gsap.delayedCall(0.22, () => {
                this.progress_signup('appear')
           
            }) 
        } else {
            if (!this.validateStep(this.currentStep)) return;

            this.buttonNext.disabled = true;
            gsap.delayedCall(0.22, () => {
    
                this.currentStep++;
                this.updateStepUI();
            })
        }

    }

    updateStepUI() {
        const layers = this.form.querySelectorAll('.signup-layer');
        layers.forEach(layer => {
            const index = parseInt(layer.getAttribute('data-index'));
            // gsap.set(layer, { display: index === this.currentStep ? 'block' : 'none' });

            if (index === this.currentStep) {

                gsap.delayedCall(0.4, () => {
                    gsap.set(layer, { 
                        display: 'flex',
                    })
                    gsap.to(layer, {
                        autoAlpha: 1, 
                    
                        duration: 0.6,
                        ease: 'power1.inOut',
                        delay: 0.0,
                        onComplete: () => {
                            if (this.currentStep == 1) {
                                this.buttonSubmit.disabled = true;
                            }
                        }
                    })
                })

              
            } else {
                gsap.set(layer, { pointerEvents: 'none' })
                gsap.to(layer, {
                    autoAlpha: 0,
                    display: 'none',
                    duration: 0.4, 
                    ease: 'power1.inOut',
                    onComplete: () => {
           
                    }
                })

   
            }

        });
        this.validateForm();
    }

    validateStep(step) {
        if (step === 0) {
            const emailValid = this.emailId.value.length > 0 && this.emailDomain.value.length > 0;
            const passwordValid = this.password.value.length >= 6 && this.password.value === this.passwordReenter.value;
            const codeValid = this.isVerified; // 인증 완료 여부 확인
            return emailValid && passwordValid && codeValid;
        } else if (step === 1) {
            return this.validateForm();
        }
        return false;
    }
    validateForm() {
        let isValid = false;
        const verifyCodeInput = document.getElementById('verify-code');
    
        // 숫자만 허용하도록 입력 실시간 필터링
        verifyCodeInput.addEventListener('input', function(e) {
            // 숫자가 아닌 문자는 제거
            this.value = this.value.replace(/[^0-9]/g, '');
            // 6자 초과 시 자르기
            if (this.value.length > 6) {
                this.value = this.value.slice(0, 6);
            }
        });
        if (this.currentStep === 0) {
            // 도메인 형식 체크를 위한 정규표현식 (@ 이후 부분만)
            const domainPattern = /^[^\s@]+\.[^\s@]+$/;
            const emailValid = this.emailId.value.length > 0 && 
                             this.emailDomain.value.length > 0 && 
                             domainPattern.test(this.emailDomain.value);
            
            const passwordValid = this.password.value.length >= 6 && 
                                this.password.value === this.passwordReenter.value;
            const codeValid = this.isVerified;
    
            // 이메일 입력 여부에 따라 인증번호 UI 활성화
            if (emailValid) {
                this.emailCodeContainer.classList.remove('is-disabled');
                this.buttonVerifyCode.disabled = false;
            } else {
                this.emailCodeContainer.classList.add('is-disabled');
                this.buttonVerifyCode.disabled = true;
                this.verificationCode.disabled = true;
            }
    
            isValid = emailValid && passwordValid && codeValid;
            this.buttonNext.disabled = !isValid;
        } else if (this.currentStep === 1) {
            const nicknameValid = this.username.value.length >= 2 && 
                                this.username.value.length <= 9;
            const requiredChecks = document.querySelectorAll('.input-require input[type="checkbox"]');
            const allChecked = Array.from(requiredChecks).every(checkbox => checkbox.checked);
            isValid = nicknameValid && allChecked;
            this.buttonSubmit.disabled = !isValid;
            this.app.userDATA.name = this.username.value;
        }
        return isValid;
    }

    initCheckBox() {
        const validate = () => this.validateForm();
        const errorText = document.querySelector('.error-nickname .error-text');
        const appearError = () => {
            errorText.innerHTML = '닉네임은 2~9자여야 해요';
            gsap.to(errorText, { autoAlpha: 1, duration: 0.2, ease: 'power1.out' });
        };
        const disappearError = () => {
            gsap.to(errorText, { autoAlpha: 0, duration: 0.2, ease: 'power1.out' });
        };
        const textValidate = () => {
            const MIN = this.username.getAttribute('min');
            const MAX = this.username.getAttribute('max');


            const value = this.username.value.length;
            if (value < MIN || value > MAX) {
                appearError();
                if (value > MAX) this.username.value = this.username.value.substring(0, MAX);
            } else {
                disappearError();
            }
            validate();
        };
        const filterNickname = (e) => {

            const MIN = this.username.getAttribute('min');
            const MAX = this.username.getAttribute('max');

            const input = e.target;
            const regex = /^[ㄱ-ㅎ가-힣a-zA-Z]+$/; // 한글과 영문만 허용
            let value = input.value;

            // 띄어쓰기 제거
            value = value.replace(/\s/g, '');

            // 허용되지 않은 문자 필터링
            value = value.split('').filter(char => regex.test(char)).join('');

            // 입력값 적용
            if (value !== input.value) {
                input.value = value;
                validate(); // 유효성 재검사
            }

            // 최대 길이 초과 시 잘라내기
            if (value.length > MAX) {
                input.value = value.substring(0, MAX);
                appearError();
            } else if (value.length >= MIN && value.length <= MAX) {
                disappearError();
            }
        };

        this.username.addEventListener('input', filterNickname);
        this.username.addEventListener('keydown', textValidate);
        this.username.addEventListener('keyup', textValidate);
        this.emailId.addEventListener('input', validate);
        this.emailDomain.addEventListener('input', validate);
        this.password.addEventListener('input', validate);
        this.passwordReenter.addEventListener('input', validate);
        this.verificationCode.addEventListener('input', validate);

        gsap.utils.toArray('.input-checkbox-group').forEach(group => {
            if (group.hasAttribute('data-load')) return;
            group.setAttribute('data-load', true);

            const checkAll = group.querySelector('#input-terms-all');
            const requiredChecks = group.querySelectorAll('.input-require input[type="checkbox"]');

            function updateCheckAll() {
                const allChecked = Array.from(requiredChecks).every(checkbox => checkbox.checked);
                checkAll.checked = allChecked;
                validate();
            }

            checkAll.addEventListener('change', function () {
                const isChecked = this.checked;
                requiredChecks.forEach(checkbox => checkbox.checked = isChecked);
                validate();
            });

            requiredChecks.forEach(checkbox => checkbox.addEventListener('change', updateCheckAll));
        });
    }

    // 애니메이션 관련 메서드 (변경 없음)
    appearLayer(layer) {
        const sheet = layer.querySelector('.layer-content');
        const yv = 60;
        gsap.set(layer, { display: 'flex', zIndex: 1000, pointerEvents: 'auto' });
        gsap.set(sheet, { y: yv });
        gsap.to(sheet, { opacity: 1, duration: 0.35, ease: 'power2.out', delay: 0.1 });
        gsap.to(sheet, { y: 0, duration: 0.35, ease: 'power2.out', delay: 0.1 });
    }

    disappearLayer(layer) {
        const sheet = layer.querySelector('.layer-content');
        const yv = 60;
        gsap.to(sheet, { opacity: 0, duration: 0.3, ease: 'power1.inOut' });
        gsap.to(sheet, { 
            y: (yv / 2), 
            duration: 0.3, 
            ease: 'power1.inOut', 
            onComplete: () => {
                sheet.scrollTo(0, 0);
                gsap.set(layer, { display: 'none', pointerEvents: 'none' });
                gsap.killTweensOf(layer, 'all');
            }
        });
    }

    apperLayer(layer, _callback) {
        if (layer.classList.contains('is-selected')) return;
        gsap.set(layer, { display: 'flex', pointerEvents: 'auto' });
        layer.classList.add('is-selected');
        gsap.to(layer, { 
            autoAlpha: 1, 
            duration: 0.25, 
            ease: 'power1.inOut', 
            onComplete: () => _callback && _callback() 
        });
    }

    disapperLayer(layer, _callback) {
        gsap.set(layer, { pointerEvents: 'none' });
        gsap.to(layer, { 
            autoAlpha: 0, 
            duration: 0.3, 
            ease: 'power1.inOut', 
            onComplete: () => {
                gsap.set(layer, { 
                    display: 'none', 
                    onComplete: () => {
                        layer.classList.remove('is-selected');
                        _callback && _callback();
                    } 
                });
            }
        });
    }

    progress_signup(state, callback) {
        const layer = document.querySelector('.layer-signup');
        if (state === 'appear') {
            this.apperLayer(layer, callback);
        } else if (state === 'disappear') {
            this.disapperLayer(layer, callback);
        }
    }

    progress_onboarding(state, callback) {
        const layer = document.querySelector('.layer-onboarding');
        const video = layer.querySelector('video');
        const titleAnim = new TextTranslate(layer.querySelector('.layer-title .title'), 'words');
        const subAnim = new TextTranslate(layer.querySelector('.layer-title .sub'), 'lines');

        const toggleVideo = (state) => {
            if (state === 'play' && video.paused) video.play();
            else if (state === 'pause' && !video.paused) video.pause();
        };

        if (state === 'appear') {
            window.history.replaceState(null, '', '/');
            const cb = () => {
                toggleVideo('play');
            };
            this.apparTransition(layer, { _class: titleAnim, index: 0 }, { _class: subAnim, index: 0 }, cb);
        } else if (state === 'disappear') {
            this.disapparTransition(layer);
        }
    }

    apparTransition(layer, title, sub, _callback) {
        if (layer.classList.contains('is-selected')) return;
        const content = layer.querySelectorAll('.layer-content');
        const floating = layer.querySelector('.layer-ui');
        layer.classList.add('is-selected');
        gsap.set(layer, { display: 'flex', pointerEvents: 'auto', autoAlpha: 1 });
        gsap.to(content, { autoAlpha: 1, duration: 0.6, ease: 'power1.inOut' });
        gsap.to(floating, { 
            autoAlpha: 1, 
            duration: 0.4, 
            ease: 'power1.inOut', 
            delay: 0.6, 
            onComplete: () => _callback && _callback() 
        });
        if (title) title._class.appear(title.index);
        if (sub) sub._class.appear(sub.index, 0.3);
    }

    disapparTransition(layer, _callback) {
        layer.classList.remove('is-selected');
        gsap.to(layer, { 
            autoAlpha: 0, 
            duration: 0.4, 
            ease: 'power1.inOut', 
            onComplete: () => {
                gsap.set(layer, { display: 'none', pointerEvents: 'none' });
                _callback && _callback();
            }
        });
    }
}