import gsap from 'gsap';

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWithoutCommas(num) {
   return parseFloat(num.replace(/,/g, ''));
}

export function lerp(start, end, t) {
    return start + (end - start) * t;
}

export function animateCounter(target, startValue, endValue, duration) {
    const obj = { value: startValue }; // Initial value object

    gsap.to(obj, {
        value: endValue,
        duration: duration,
        ease: "power1.out", // Easing function
        onUpdate: () => {
            target.innerHTML = Math.round(obj.value); // Update innerHTML with rounded value
        },
    });
}

export function appearAlert(_callback) {

    const layer = document.createElement('div');

    layer.classList.add('layer')
    layer.classList.add('layer-alert')
    layer.innerHTML = `
        <div class="background"></div>
        <div class="alert-container">
            <div class="alert-title">
                <div class="sub">이미지를 삭제하시겠어요?</div>
            </div>
            <div class="alert-buttons">
                <button class="button-alert button-cancel">취소</button>
                <button class="button-alert button-confirm">확인</button>
            </div>
        </div>
    `

    document.body.classList.add('is-lock')

    const background = layer.querySelector('.background');
    const container = layer.querySelector('.alert-container');

    document.body.appendChild(layer);

    gsap.set(layer, { display: 'flex', zIndex: 999 })
    gsap.to(background, { autoAlpha: 1, duration: 0.3, ease: 'power1.inOut' })
    gsap.to(container, { autoAlpha: 1, duration: 0.4, ease: 'power1.inOut', onComplete: () => {
        gsap.set(layer, { pointerEvents: 'auto', onComplete: () => {
            if (_callback) _callback()
        } })
    } })

    return layer;
    
}

export function disappearAlert(layer) {

    const background = layer.querySelector('.background');
    const container = layer.querySelector('.alert-container');


    gsap.set(layer, { pointerEvents: 'none' })
    gsap.to(background, { autoAlpha: 0, duration: 0.3, ease: 'power1.inOut' })
    gsap.to(container, { autoAlpha: 0, duration: 0.4, ease: 'power1.inOut', onComplete: () => {
        gsap.set(layer, { display: 'none', onComplete: () => {
            document.body.classList.remove('is-lock')
            layer.remove()
        } })
    } })
}

export function createLoading() {
    const loading = document.createElement('div');

    const loadingAnim = lottie.loadAnimation({
        container: this.loadingLayer.querySelector('.loading'), // <div> 요소를 컨테이너로 설정
        renderer: 'svg', // SVG 렌더러를 사용하여 품질을 높임
        loop: true,
        autoplay: false,
        animationData: animationData,
    });
}


export function imageToBase64(imgElement) {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        canvas.width = imgElement.naturalWidth;
        canvas.height = imgElement.naturalHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(imgElement, 0, 0);

        try {
            const base64String = canvas.toDataURL("image/jpeg").split(",")[1]; // "data:image/jpeg;base64," 제거
            resolve(base64String);
        } catch (error) {
            reject(error);
        }
    });
}



export function floatingServiceHoldButton() {
    document.body.classList.add('is-service-hold');
    const content = document.createElement('div');
    content.disabled = true;
    content.classList.add('button-text');
    content.classList.add('button-service-hold');
    content.classList.add('--prevent');
    content.innerHTML = `<span class="label-sub">접속자가 많아 지연되고 있어요</span><span class="label">잠시 후 다시 시도해주세요</span>`
    return content;
}

export function floatingCreditExhausted() {
    document.body.classList.add('is-credit-exhausted');
  
    const content = document.createElement('div');
    content.classList.add('button-text');
    content.classList.add('button-disable');
    content.classList.add('--prevent');
  
    function getTimeUntilMidnight() {
        const now = new Date();
        const kstOffset = 9 * 60 * 60 * 1000; // UTC+9 (9시간) in milliseconds
        const utcNow = now.getTime() + (now.getTimezoneOffset() * 60 * 1000); // UTC 시간 계산
        const kstNow = new Date(utcNow + kstOffset); // 한국 시간
    
        // 다음 날 00:00 계산
        const midnight = new Date(kstNow);
        midnight.setDate(midnight.getDate() + 1); // 다음 날로 설정
        midnight.setHours(0, 0, 0, 0); // 00:00:00으로 설정
    
        const timeDiff = midnight - kstNow; // 밀리초 단위
        const hours = String(Math.floor(timeDiff / (1000 * 60 * 60))).padStart(2, '0');

        return `${hours}시간`;
    }
    // 초기 시간 표시
    const remainingTime = getTimeUntilMidnight();
    content.innerHTML = `
      <span class="label">오늘 크레딧을 모두 사용했어요</span>
      <span class="label-sub"><span class="label-time">${remainingTime}</span> 뒤에 생성할 수 있어요</span>
    `;
  
    return content;
  }
export function creteErrorState(state) {

    const stateMessage = {
        "is-credit-exhausted": '오늘 크레딧을 모두 사용했어요',
        "service-hold": '서비스를 점검 중이에요',
        "service-temp-hold":'이용자가 많아 잠시 후 다시 시도해주세요'
    }
    document.body.classList.add(`is-${state}`);
    const content = document.createElement('div');
    content.classList.add('error');
    content.innerHTML = stateMessage[state];
    return content;
}

